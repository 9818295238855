// This file was automatically generated.  Do not modify.

'use strict';

goog.provide('Blockly.ScratchMsgs.allLocales');

goog.require('Blockly.ScratchMsgs');

Blockly.ScratchMsgs.locales["ab"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ar"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["am"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["az"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["id"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["bg"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ca"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["cs"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["cy"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["da"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["de"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["et"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["el"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["en"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr",
  "ML5MODALBUTTON": "AI visualization window"
};

Blockly.ScratchMsgs.locales["es"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["es-419"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["eu"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["fa"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["fr"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ga"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["gd"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["gl"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ko"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["he"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["hr"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["zu"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["is"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["it"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ckb"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["lv"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["lt"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["hu"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["mi"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["nl"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ja"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ja-Hira"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["nb"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["nn"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["th"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["pl"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["pt"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["pt-br"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ro"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["ru"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["sr"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["sk"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["sl"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["fi"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["sv"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["vi"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["tr"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["uk"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["zh-cn"] =
{
  "CONTROL_FOREVER": "重复执行",
  "CONTROL_REPEAT": "重复执行 %1 次",
  "CONTROL_IF": "如果 %1 那么",
  "CONTROL_ELSE": "否则",
  "CONTROL_STOP": "停止",
  "CONTROL_STOP_ALL": "全部脚本",
  "CONTROL_STOP_THIS": "这个脚本",
  "CONTROL_STOP_OTHER": "该角色的其他脚本",
  "CONTROL_WAIT": "等待 %1 秒",
  "CONTROL_WAITUNTIL": "等待 %1",
  "CONTROL_REPEATUNTIL": "重复执行直到 %1",
  "CONTROL_WHILE": "当 %1 重复执行",
  "CONTROL_FOREACH": "对于 %2 中的每个 %1",
  "CONTROL_STARTASCLONE": "当作为克隆体启动时",
  "CONTROL_CREATECLONEOF": "克隆 %1",
  "CONTROL_CREATECLONEOF_MYSELF": "自己",
  "CONTROL_DELETETHISCLONE": "删除此克隆体",
  "CONTROL_COUNTER": "计数器",
  "CONTROL_INCRCOUNTER": "计数器加一",
  "CONTROL_CLEARCOUNTER": "计数器归零",
  "CONTROL_ALLATONCE": "所有脚本",
  "DATA_SETVARIABLETO": "将 %1 设为 %2",
  "DATA_CHANGEVARIABLEBY": "将 %1 增加 %2",
  "DATA_SHOWVARIABLE": "显示变量 %1",
  "DATA_HIDEVARIABLE": "隐藏变量 %1",
  "DATA_ADDTOLIST": "将 %1 加入 %2",
  "DATA_DELETEOFLIST": "删除 %2 的第 %1 项",
  "DATA_DELETEALLOFLIST": "删除 %1 的全部项目",
  "DATA_INSERTATLIST": "在 %3 的第 %2 项前插入 %1",
  "DATA_REPLACEITEMOFLIST": "将 %2 的第 %1 项替换为 %3",
  "DATA_ITEMOFLIST": "%2 的第 %1 项",
  "DATA_ITEMNUMOFLIST": "%2 中第一个 %1 的编号",
  "DATA_LENGTHOFLIST": "%1 的项目数",
  "DATA_LISTCONTAINSITEM": "%1 包含 %2 ?",
  "DATA_SHOWLIST": "显示列表 %1",
  "DATA_HIDELIST": "隐藏列表 %1",
  "DATA_INDEX_ALL": "全部",
  "DATA_INDEX_LAST": "末尾",
  "DATA_INDEX_RANDOM": "随机",
  "EVENT_WHENFLAGCLICKED": "当 %1 被点击",
  "EVENT_WHENTHISSPRITECLICKED": "当角色被点击",
  "EVENT_WHENSTAGECLICKED": "当舞台被点击",
  "EVENT_WHENTOUCHINGOBJECT": "当该角色碰到 %1",
  "EVENT_WHENBROADCASTRECEIVED": "当接收到 %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "当背景换成 %1",
  "EVENT_WHENGREATERTHAN": "当 %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "计时器",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "响度",
  "EVENT_BROADCAST": "广播 %1",
  "EVENT_BROADCASTANDWAIT": "广播 %1 并等待",
  "EVENT_WHENKEYPRESSED": "当按下 %1 键",
  "EVENT_WHENKEYPRESSED_SPACE": "空格",
  "EVENT_WHENKEYPRESSED_LEFT": "←",
  "EVENT_WHENKEYPRESSED_RIGHT": "→",
  "EVENT_WHENKEYPRESSED_DOWN": "↓",
  "EVENT_WHENKEYPRESSED_UP": "↑",
  "EVENT_WHENKEYPRESSED_ANY": "任意",
  "LOOKS_SAYFORSECS": "说 %1 %2 秒",
  "LOOKS_SAY": "说 %1",
  "LOOKS_HELLO": "你好！",
  "LOOKS_THINKFORSECS": "思考 %1 %2 秒",
  "LOOKS_THINK": "思考 %1",
  "LOOKS_HMM": "嗯……",
  "LOOKS_SHOW": "显示",
  "LOOKS_HIDE": "隐藏",
  "LOOKS_HIDEALLSPRITES": "隐藏所有角色",
  "LOOKS_EFFECT_COLOR": "颜色",
  "LOOKS_EFFECT_FISHEYE": "鱼眼",
  "LOOKS_EFFECT_WHIRL": "漩涡",
  "LOOKS_EFFECT_PIXELATE": "像素化",
  "LOOKS_EFFECT_MOSAIC": "马赛克",
  "LOOKS_EFFECT_BRIGHTNESS": "亮度",
  "LOOKS_EFFECT_GHOST": "虚像",
  "LOOKS_CHANGEEFFECTBY": "将 %1 特效增加 %2",
  "LOOKS_SETEFFECTTO": "将 %1 特效设定为 %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "清除图形特效",
  "LOOKS_CHANGESIZEBY": "将大小增加 %1",
  "LOOKS_SETSIZETO": "将大小设为 %1",
  "LOOKS_SIZE": "大小",
  "LOOKS_CHANGESTRETCHBY": "伸缩%1",
  "LOOKS_SETSTRETCHTO": "设置伸缩为%1 %",
  "LOOKS_SWITCHCOSTUMETO": "换成 %1 造型",
  "LOOKS_NEXTCOSTUME": "下一个造型",
  "LOOKS_SWITCHBACKDROPTO": "换成 %1 背景",
  "LOOKS_GOTOFRONTBACK": "移到最 %1 ",
  "LOOKS_GOTOFRONTBACK_FRONT": "前面",
  "LOOKS_GOTOFRONTBACK_BACK": "后面",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "%1 %2 层",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "前移",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "后移",
  "LOOKS_BACKDROPNUMBERNAME": "背景 %1",
  "LOOKS_COSTUMENUMBERNAME": "造型 %1",
  "LOOKS_NUMBERNAME_NUMBER": "编号",
  "LOOKS_NUMBERNAME_NAME": "名称",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "换成 %1 背景并等待",
  "LOOKS_NEXTBACKDROP_BLOCK": "下一个背景",
  "LOOKS_NEXTBACKDROP": "下一个背景",
  "LOOKS_PREVIOUSBACKDROP": "上一个背景",
  "LOOKS_RANDOMBACKDROP": "随机背景",
  "MOTION_MOVESTEPS": "移动 %1 步",
  "MOTION_TURNLEFT": "左转 %1 %2 度",
  "MOTION_TURNRIGHT": "右转 %1 %2 度",
  "MOTION_POINTINDIRECTION": "面向 %1 方向",
  "MOTION_POINTTOWARDS": "面向 %1",
  "MOTION_POINTTOWARDS_POINTER": "鼠标指针",
  "MOTION_POINTTOWARDS_RANDOM": "随机方向",
  "MOTION_GOTO": "移到 %1",
  "MOTION_GOTO_POINTER": "鼠标指针",
  "MOTION_GOTO_RANDOM": "随机位置",
  "MOTION_GOTOXY": "移到 x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "在 %1 秒内滑行到 x: %2 y: %3",
  "MOTION_GLIDETO": "在 %1 秒内滑行到 %2",
  "MOTION_GLIDETO_POINTER": "鼠标指针",
  "MOTION_GLIDETO_RANDOM": "随机位置",
  "MOTION_CHANGEXBY": "将x坐标增加 %1",
  "MOTION_SETX": "将x坐标设为 %1",
  "MOTION_CHANGEYBY": "将y坐标增加 %1",
  "MOTION_SETY": "将y坐标设为 %1",
  "MOTION_IFONEDGEBOUNCE": "碰到边缘就反弹",
  "MOTION_SETROTATIONSTYLE": "将旋转方式设为 %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "左右翻转",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "不可旋转",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "任意旋转",
  "MOTION_XPOSITION": "x 坐标",
  "MOTION_YPOSITION": "y 坐标",
  "MOTION_DIRECTION": "方向",
  "MOTION_SCROLLRIGHT": "向右滚动 %1",
  "MOTION_SCROLLUP": "向上滚动 %1",
  "MOTION_ALIGNSCENE": "和场景 %1 对齐",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "左下角",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "右下角",
  "MOTION_ALIGNSCENE_MIDDLE": "中间",
  "MOTION_ALIGNSCENE_TOPLEFT": "左上角",
  "MOTION_ALIGNSCENE_TOPRIGHT": "右上角",
  "MOTION_XSCROLL": "x滚动位置",
  "MOTION_YSCROLL": "y滚动位置",
  "MOTION_STAGE_SELECTED": "选中了舞台：不可使用运动类积木",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "在 %1 和 %2 之间取随机数",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 与 %2",
  "OPERATORS_OR": "%1 或 %2",
  "OPERATORS_NOT": "%1 不成立",
  "OPERATORS_JOIN": "连接 %1 和 %2",
  "OPERATORS_JOIN_APPLE": "苹果",
  "OPERATORS_JOIN_BANANA": "香蕉",
  "OPERATORS_LETTEROF": "%2 的第 %1 个字符",
  "OPERATORS_LETTEROF_APPLE": "果",
  "OPERATORS_LENGTH": "%1 的字符数",
  "OPERATORS_CONTAINS": "%1 包含 %2 ?",
  "OPERATORS_MOD": "%1 除以 %2 的余数",
  "OPERATORS_ROUND": "四舍五入 %1",
  "OPERATORS_MATHOP": "%1 %2",
  "OPERATORS_MATHOP_ABS": "绝对值",
  "OPERATORS_MATHOP_FLOOR": "向下取整",
  "OPERATORS_MATHOP_CEILING": "向上取整",
  "OPERATORS_MATHOP_SQRT": "平方根",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "定义 %1",
  "SENSING_TOUCHINGOBJECT": "碰到 %1 ?",
  "SENSING_TOUCHINGOBJECT_POINTER": "鼠标指针",
  "SENSING_TOUCHINGOBJECT_EDGE": "舞台边缘",
  "SENSING_TOUCHINGCOLOR": "碰到颜色 %1 ?",
  "SENSING_COLORISTOUCHINGCOLOR": "颜色 %1 碰到 %2 ?",
  "SENSING_DISTANCETO": "到 %1 的距离",
  "SENSING_DISTANCETO_POINTER": "鼠标指针",
  "SENSING_ASKANDWAIT": "询问 %1 并等待",
  "SENSING_ASK_TEXT": "你叫什么名字？",
  "SENSING_ANSWER": "回答",
  "SENSING_KEYPRESSED": "按下 %1 键?",
  "SENSING_MOUSEDOWN": "按下鼠标?",
  "SENSING_MOUSEX": "鼠标的x坐标",
  "SENSING_MOUSEY": "鼠标的y坐标",
  "SENSING_SETDRAGMODE": "将拖动模式设为 %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "可拖动",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "不可拖动",
  "SENSING_LOUDNESS": "响度",
  "SENSING_LOUD": "响声？",
  "SENSING_TIMER": "计时器",
  "SENSING_RESETTIMER": "计时器归零",
  "SENSING_OF": "%2 的 %1",
  "SENSING_OF_XPOSITION": "x 坐标",
  "SENSING_OF_YPOSITION": "y 坐标",
  "SENSING_OF_DIRECTION": "方向",
  "SENSING_OF_COSTUMENUMBER": "造型编号",
  "SENSING_OF_COSTUMENAME": "造型名称",
  "SENSING_OF_SIZE": "大小",
  "SENSING_OF_VOLUME": "音量",
  "SENSING_OF_BACKDROPNUMBER": "背景编号",
  "SENSING_OF_BACKDROPNAME": "背景名称",
  "SENSING_OF_STAGE": "舞台",
  "SENSING_CURRENT": "当前时间的 %1",
  "SENSING_CURRENT_YEAR": "年",
  "SENSING_CURRENT_MONTH": "月",
  "SENSING_CURRENT_DATE": "日",
  "SENSING_CURRENT_DAYOFWEEK": "星期",
  "SENSING_CURRENT_HOUR": "时",
  "SENSING_CURRENT_MINUTE": "分",
  "SENSING_CURRENT_SECOND": "秒",
  "SENSING_DAYSSINCE2000": "2000年至今的天数",
  "SENSING_USERNAME": "用户名",
  "SENSING_USERID": "用户id",
  "SOUND_PLAY": "播放声音 %1",
  "SOUND_PLAYUNTILDONE": "播放声音 %1 等待播完",
  "SOUND_STOPALLSOUNDS": "停止所有声音",
  "SOUND_SETEFFECTO": "将 %1 音效设为 %2",
  "SOUND_CHANGEEFFECTBY": "将 %1 音效增加 %2",
  "SOUND_CLEAREFFECTS": "清除音效",
  "SOUND_EFFECTS_PITCH": "音调",
  "SOUND_EFFECTS_PAN": "左右平衡",
  "SOUND_CHANGEVOLUMEBY": "将音量增加 %1",
  "SOUND_SETVOLUMETO": "将音量设为 %1%",
  "SOUND_VOLUME": "音量",
  "SOUND_RECORD": "录制…",
  "CATEGORY_MOTION": "运动",
  "CATEGORY_LOOKS": "外观",
  "CATEGORY_SOUND": "声音",
  "CATEGORY_EVENTS": "事件",
  "CATEGORY_CONTROL": "控制",
  "CATEGORY_SENSING": "侦测",
  "CATEGORY_OPERATORS": "运算",
  "CATEGORY_VARIABLES": "变量",
  "CATEGORY_MYBLOCKS": "函数",
  "DUPLICATE": "复制",
  "DELETE": "删除",
  "ADD_COMMENT": "添加注释",
  "REMOVE_COMMENT": "删除注释",
  "DELETE_BLOCK": "删除",
  "DELETE_X_BLOCKS": "删除 %1 积木",
  "DELETE_ALL_BLOCKS": "删除全部 %1 积木？",
  "CLEAN_UP": "整理积木",
  "HELP": "帮助",
  "UNDO": "撤销",
  "REDO": "重做",
  "EDIT_PROCEDURE": "编辑",
  "SHOW_PROCEDURE_DEFINITION": "查看定义",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "说些什么……",
  "COLOUR_HUE_LABEL": "颜色",
  "COLOUR_SATURATION_LABEL": "饱和度",
  "COLOUR_BRIGHTNESS_LABEL": "亮度",
  "CHANGE_VALUE_TITLE": "更改变量：",
  "RENAME_VARIABLE": "修改变量名",
  "RENAME_VARIABLE_STRING": "修改字符类型变量名",
  "RENAME_VARIABLE_NUMBER": "修改数字类型变量名",
  "RENAME_VARIABLE_LIST": "修改列表变量名",
  "RENAME_VARIABLE_TITLE": "将所有的「%1」变量名改为：",
  "RENAME_VARIABLE_MODAL_TITLE": "修改变量名",
  "NEW_VARIABLE": "新建变量",
  "NEW_VARIABLE_TITLE": "新变量名：",
  "VARIABLE_MODAL_TITLE": "新建变量",
  "VARIABLE_ALREADY_EXISTS": "已经存在名为「%1」的变量。",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "已经存在一个名为「%1」的变量，其类型为「%2」。",
  "DELETE_VARIABLE_CONFIRMATION": "删除%1处「%2」变量吗？",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "无法删除变量「%1」，因为函数「%2」的定义中用到了它",
  "DELETE_VARIABLE": "删除变量「%1」",
  "DELETE_VARIABLE_STRING": "删除字符变量「%1」",
  "DELETE_VARIABLE_NUMBER": "删除数字变量「%1」",
  "DELETE_VARIABLE_LIST": "删除列表变量「%1」",
  "NEW_PROCEDURE": "自定义模块",
  "PROCEDURE_ALREADY_EXISTS": "已经存在名为「%1」的程序。",
  "PROCEDURE_DEFAULT_NAME": "积木名称",
  "NEW_LIST": "新建列表",
  "NEW_LIST_TITLE": "新的列表名：",
  "LIST_MODAL_TITLE": "新建列表",
  "LIST_ALREADY_EXISTS": "名为 「%1」 的列表已存在。",
  "RENAME_LIST_TITLE": "将所有的「%1」列表改名为：",
  "RENAME_LIST_MODAL_TITLE": "修改列表名",
  "DEFAULT_LIST_ITEM": "东西",
  "DELETE_LIST": "删除「%1」列表",
  "RENAME_LIST": "修改列表名",
  "NEW_BROADCAST_MESSAGE": "新消息",
  "NEW_BROADCAST_MESSAGE_TITLE": "新消息的名称：",
  "BROADCAST_MODAL_TITLE": "新消息",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "消息1",
  "DELETE_PROCEDURE": "要删除自定义积木块, 首先应移除所有对它的引用",
  "OK": "确定",
  "PROMPT": "提示",
  "STOP": "停止",
  "STRING_TYPE": "string",
  "NEW_STRING": "新建字符类型变量",
  "WIFI": "WIFI",
  "USERNAME": "wifi名称",
  "PWD": "wifi密码",
  "IP": "IP地址",
  "IOT_SERVICE": "物联网平台参数",
  "IOT_ID": "Iot_id",
  "IOT_PWD": "Iot_pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP配置",
  "OBLOQ_IP": "IP地址",
  "OBLOQ_PORT": "端口号",
  "ADD_TOPIC": "添加Topic, 最多只能添加4个",
  "DELETE_TOPIC": "删除 Topic",
  "IOT_SERVER": "服务器",
  "CHINA": "中国",
  "GLOBAL": "全球",
  "LOW_C": "1 低 C/C3",
  "LOW_C$": "1# 低 C#/C#3",
  "LOW_D": "2 低 D/D3",
  "LOW_D$": "2# 低 D#/D#3",
  "LOW_E": "3 低 E/E3",
  "LOW_F": "4 低 F/F3",
  "LOW_F$": "4# 低 F#/F#3",
  "LOW_G": "5 低 G/G3",
  "LOW_G$": "5# 低 G#/G#3",
  "LOW_A": "6 低 A/A3",
  "LOW_A$": "6# 低 A#/A#3",
  "LOW_B": "7 低 B/B3",
  "MIDDLE_C": "1 中 C/C4",
  "MIDDLE_C$": "1# 中 C#/C#4",
  "MIDDLE_D": "2 中 D/D4",
  "MIDDLE_D$": "2# 中 D#/D#4",
  "MIDDLE_E": "3 中 E/E4",
  "MIDDLE_F": "4 中 F/F4",
  "MIDDLE_F$": "4# 中 F#/F#4",
  "MIDDLE_G": "5 中 G/G4",
  "MIDDLE_G$": "5# 中 G#/G#4",
  "MIDDLE_A": "6 中 A/A4",
  "MIDDLE_A$": "6# 中 A#/A#4",
  "MIDDLE_B": "7 中 B/B4",
  "HIGH_C": "1 高 C/C5",
  "HIGH_C$": "1# 高 C#/C#5",
  "HIGH_D": "2 高 D/D5",
  "HIGH_D$": "2# 高 D#/D#5",
  "HIGH_E": "3 高 E/E5",
  "HIGH_F": "4 高 F/F5",
  "HIGH_F$": "4# 高 F#/F#5",
  "HIGH_G": "5 高 G/G5",
  "HIGH_G$": "5# 高 G#/G#5",
  "HIGH_A": "6 高 A/A5",
  "HIGH_A$": "6# 高 A#/A#5",
  "HIGH_B": "7 高 B/B5",
  "OPERATORS_STRINGTONUMBER": "将字符串 %1 转换为 %2",
  "INTEGER": "整数",
  "DECIMAL": "小数",
  "OPERATORS_NUMTOASCII": "将数字 %1 转换为 ASCII字符",
  "OPERATORS_STRINGTOASCII": "将字符 %1 转换为 ASCII数值",
  "OPERATORS_NUMTOSTRING": "将数字 %1 转换为字符串",
  "OPERATORS_MAP": "映射 %1 从[ %2 , %3 ] 到[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "约束 %1 介于(最小值) %2 和(最大值) %3 之间",
  "STEPS_PER_TURN": "每转步数",
  "ROTATE_SPEED": "转速(r/min)",
  "NEW_AI": "创建AI模型",
  "ASK_AND_PAINT": "请写一个数字",
  "GET_IDENTIFIED_NUM": "数字识别",
  "READ_NUM_AND_SAY": "说出识别的数字",
  "IMAGE_ADDR": "图像地址",
  "IMAGE_PREVIEW": "图像预览",
  "IMAGE_OPEN": "打开",
  "IMAGE_SIZE": "图像尺寸",
  "IMAGE_WIDTH": "宽",
  "IMAGE_HEIGHT": "高",
  "VARIABLE": "变量 %1",
  "VARIABLE_LIST": "列表 %1",
  "SET_STRING_VARIABLE": "设置 %1 的值为 %2",
  "STRING_START_WITH": "%1 以 %2 字符串开始",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 字典 %3 键 %4 的值",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 字典 %3 键 %4 的值设置为 %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 字典 %3 删除键 %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 清空字典 %3",
  "DATA_MPITEMOFLIST": "%1 %2 列表 %3 第 %4 项",
  "DATA_MPINTERCEPTITEM": "%1 %2 列表 %3 第%4项 到 第%5项",
  "DATA_MPINSERTATLIST": "%1 %2 列表 %3 第 %4 项 %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "插入",
  "DATA_MPINSERTATLIST_CHANGE": "改为",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 列表 %3 删除 第 %4 项",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 列表 %3 尾部追加 %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 列表 %3 转变为元组",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 列表 %3 元素 %4 的位置",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 列表 %3 排序为 %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "升序",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "降序",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "逆序",
  "OPERATORS_RANDOMA": "%1 %2 在 %3 到 %4 间取随机数",
  "OPERATORS_MODA": "%1 %2 %3 除以 %4 的余数",
  "OPERATORS_ROUNDA": "%1 %2 四舍五入 %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "绝对值",
  "OPERATORS_MATHOP_FLOORA": "向上取整",
  "OPERATORS_MATHOP_CEILINGA": "向下取整",
  "OPERATORS_MATHOP_SQRTA": "平方根",
  "OPERATORS_MATHOP_FIVE": "%1 %2 将变量 %3 增加 %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 映射 %3 从[ %4 , %5 ] 到[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 约束 %3 介于(最小值) %4 和(最大值) %5 之间",
  "OPERATORS_MATHOP_LENGHT": "长度 %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "最大值",
  "OPERATORS_MATHOP_MAXMIN_MIN": "最小值",
  "OPERATORS_TEXTS_ONE": "%1 %2 合并 %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 获取 %3 的第 %4 个字符",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 包含 %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 获取 %4 %5 个字符 %6 %7个字符",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 将字符串 %3 转换为 %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 将数字 %3 转换为 ASCII字符",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 将字符 %3 转换为 ASCII数值",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 将数字 %3 转换为字符串",
  "NEW_MC": "新建变量",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "重命名所有变量名为 \"%1\" 的变量为:",
  "RENAME_MCNUMBER_MODAL_TITLE": "重命名变量",
  "RENAME_VARIABLE_MCNUMBER": "重命名变量",
  "DELETE_VARIABLE_MCNUMBER": "删除变量 \"%1\"",
  "TUPLE_ONE": "%1 %2 元组 %3 第 %4 项",
  "TUPLE_TWO": "%1 %2 元组 %3 转变为列表",
  "ALIYUN": "阿里云",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "上海",
  "QINGDAO": "青岛",
  "BEIJING": "北京",
  "ZHANGJIAKOU": "张家口",
  "HUHEHAOTE": "呼和浩特",
  "HANGZHOU": "杭州",
  "SHENZHEN": "深圳",
  "HONGKONG": "香港",
  "SINGAPORE": "新加坡",
  "SYDNEY": "悉尼",
  "KUALALUMPUR": "吉隆坡",
  "JAKARTA": "雅加达",
  "MUMBAI": "孟买",
  "TOKYO": "东京",
  "SILICONVALLEY": "硅谷",
  "VIRGINIA": "弗吉尼亚",
  "FRANKFURT": "法兰克福",
  "LONDON": "伦敦",
  "DUBAI": "迪拜",
  "IOT_PLATFORM": "物联网平台",
  "PARAMS": "参数",
  "SERVER_ATTR": "服务器地址",
  "ML5MODALBUTTON": "AI可视化窗口"
};

Blockly.ScratchMsgs.locales["zh-tw"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "%1",
  "VARIABLE_LIST": "%1",
  "SET_STRING_VARIABLE": "set %1 to %2",
  "STRING_START_WITH": "%1 start with %2",
  "DICTIONARY_HEIGHT_FIRST": "%1 %2 dictionary %3 value of key %4",
  "DICTIONARY_HEIGHT_TWO": "%1 %2 set dictionary %3 key %4 value %5",
  "DICTIONARY_HEIGHT_THREE": "%1 %2 delete dictionary %3 key %4",
  "DICTIONARY_HEIGHT_FOUR": "%1 %2 clear dictionary %3",
  "DATA_MPITEMOFLIST": "%1 %2 list %3 item of %4",
  "DATA_MPINTERCEPTITEM": "%1 %2 list %3 item %4 to item %5",
  "DATA_MPINSERTATLIST": "%1 %2 list %3 item %4 item %5 %6",
  "DATA_MPINSERTATLIST_INSERT": "insert",
  "DATA_MPINSERTATLIST_CHANGE": "change",
  "DATA_MPINSERTATLIST_FOUR": "%1 %2 list %3 delete item %4",
  "DATA_MPINSERTATLIST_FIVE": "%1 %2 list %3 tail append %4",
  "DATA_MPINSERTATLIST_SIX": "%1 %2 list %3 converted to tuple",
  "DATA_MPINSERTATLIST_SEVEN": "%1 %2 list %3 index of item %4",
  "DATA_MPINSERTATLIST_EIGHT": "%1 %2 list %3 sort %4",
  "DATA_MPINSERTATLIST_EIGHT_FALSE": "Ascend",
  "DATA_MPINSERTATLIST_EIGHT_TRUE": "Descend",
  "DATA_MPINSERTATLIST_EIGHT_REVERSE": "Reverse",
  "OPERATORS_RANDOMA": "%1 %2 takes a random number between %3 and %4",
  "OPERATORS_MODA": "%1 %2 %3 divided by the remainder of %4",
  "OPERATORS_ROUNDA": "%1 %2 rounded to %3",
  "OPERATORS_MATHOPA": "%1 %2 %3 %4",
  "OPERATORS_MATHOP_ABSA": "abs",
  "OPERATORS_MATHOP_FLOORA": "ceil",
  "OPERATORS_MATHOP_CEILINGA": "floor",
  "OPERATORS_MATHOP_SQRTA": "square",
  "OPERATORS_MATHOP_FIVE": "%1 %2 increases the variable %3 by %4",
  "OPERATORS_MATHOP_SIX": "%1 %2 map %3 from[ %4 , %5 ] to[ %6 , %7 ]",
  "OPERATORS_MATHOP_SEVEN": "%1 %2 constrain %3 between(min) %4 and(max) %5",
  "OPERATORS_MATHOP_LENGHT": "length %1",
  "OPERATORS_MATHOP_MAXMIN": "%1 %2",
  "OPERATORS_MATHOP_MAXMIN_MAX": "max",
  "OPERATORS_MATHOP_MAXMIN_MIN": "min",
  "OPERATORS_TEXTS_ONE": "%1 %2 merge %3 %4",
  "OPERATORS_TEXTS_TWO": "%1 %2 get the character of %3 with index %4",
  "OPERATORS_TEXTS_THREE": "%1 %2 %3 contain %4 ?",
  "OPERATORS_TEXTS_FOUR": "%1 %2 %3 get %4 %5 char %6 %7 char",
  "OPERATORS_TEXTS_FOUR_ONE": "第",
  "OPERATORS_TEXTS_FOUR_TWO": "倒数第",
  "OPERATORS_TEXTS_FOUR_THREE": "到第",
  "OPERATORS_TEXTS_FOUR_FOUR": "到倒数第",
  "OPERATORS_STRINGTONUMBERA": "%1 %2 converts the string %3 to %4",
  "OPERATORS_NUMTOASCIIA": "%1 %2 converts the number %3 to ASCII characters",
  "OPERATORS_STRINGTOASCIIA": "%1 %2 converts the character %3 to an ASCII value",
  "OPERATORS_NUMTOSTRINGA": "%1 %2 converts the number %3 to a string",
  "NEW_MC": "make micropython variable",
  "MCNUMBER_TYPE": "mcnumber",
  "RENAME_MCNUMBER_TITLE": "rename all variables whose variable name is \"%1\" to:",
  "RENAME_MCNUMBER_MODAL_TITLE": "rename variable",
  "RENAME_VARIABLE_MCNUMBER": "rename variable",
  "DELETE_VARIABLE_MCNUMBER": "delete variable \"%1\"",
  "TUPLE_ONE": "%1 %2 tuple %3 item %4",
  "TUPLE_TWO": "%1 %2 transform tuple %3 to list",
  "ALIYUN": "Aliyun",
  "ONENET": "OneNet",
  "EASYIOT": "Easy Iot",
  "SHANGHAI": "shanghai",
  "QINGDAO": "qingdao",
  "BEIJING": "beijing",
  "ZHANGJIAKOU": "zhangjiakou",
  "HUHEHAOTE": "huhehaote",
  "HANGZHOU": "hangzhou",
  "SHENZHEN": "shenzhen",
  "HONGKONG": "hongkong",
  "SINGAPORE": "singapore",
  "SYDNEY": "Sydney",
  "KUALALUMPUR": "Luala Lumpur",
  "JAKARTA": "Jakarta",
  "MUMBAI": "Mumbai",
  "TOKYO": "Tokyo",
  "SILICONVALLEY": "Silicon Valley",
  "VIRGINIA": "Virginia",
  "FRANKFURT": "Frankfurt",
  "LONDON": "London",
  "DUBAI": "Dubai",
  "IOT_PLATFORM": "Iot Platform",
  "PARAMS": "Params",
  "SERVER_ATTR": "Server Attr"
};

Blockly.ScratchMsgs.locales["mn"] =
{
  "CONTROL_FOREVER": "forever",
  "CONTROL_REPEAT": "repeat %1",
  "CONTROL_IF": "if %1 then",
  "CONTROL_ELSE": "else",
  "CONTROL_STOP": "stop",
  "CONTROL_STOP_ALL": "all",
  "CONTROL_STOP_THIS": "this script",
  "CONTROL_STOP_OTHER": "other scripts in sprite",
  "CONTROL_WAIT": "wait %1 seconds",
  "CONTROL_WAITUNTIL": "wait until %1",
  "CONTROL_REPEATUNTIL": "repeat until %1",
  "CONTROL_WHILE": "while %1",
  "CONTROL_FOREACH": "for each %1 in %2",
  "CONTROL_STARTASCLONE": "when I start as a clone",
  "CONTROL_CREATECLONEOF": "create clone of %1",
  "CONTROL_CREATECLONEOF_MYSELF": "myself",
  "CONTROL_DELETETHISCLONE": "delete this clone",
  "CONTROL_COUNTER": "counter",
  "CONTROL_INCRCOUNTER": "increment counter",
  "CONTROL_CLEARCOUNTER": "clear counter",
  "CONTROL_ALLATONCE": "all at once",
  "DATA_SETVARIABLETO": "set %1 to %2",
  "DATA_CHANGEVARIABLEBY": "change %1 by %2",
  "DATA_SHOWVARIABLE": "show variable %1",
  "DATA_HIDEVARIABLE": "hide variable %1",
  "DATA_ADDTOLIST": "add %1 to %2",
  "DATA_DELETEOFLIST": "delete %1 of %2",
  "DATA_DELETEALLOFLIST": "delete all of %1",
  "DATA_INSERTATLIST": "insert %1 at %2 of %3",
  "DATA_REPLACEITEMOFLIST": "replace item %1 of %2 with %3",
  "DATA_ITEMOFLIST": "item %1 of %2",
  "DATA_ITEMNUMOFLIST": "item # of %1 in %2",
  "DATA_LENGTHOFLIST": "length of %1",
  "DATA_LISTCONTAINSITEM": "%1 contains %2?",
  "DATA_SHOWLIST": "show list %1",
  "DATA_HIDELIST": "hide list %1",
  "DATA_INDEX_ALL": "all",
  "DATA_INDEX_LAST": "last",
  "DATA_INDEX_RANDOM": "random",
  "EVENT_WHENFLAGCLICKED": "when %1 clicked",
  "EVENT_WHENTHISSPRITECLICKED": "when this sprite clicked",
  "EVENT_WHENSTAGECLICKED": "when stage clicked",
  "EVENT_WHENTOUCHINGOBJECT": "when this sprite touches %1",
  "EVENT_WHENBROADCASTRECEIVED": "when I receive %1",
  "EVENT_WHENBACKDROPSWITCHESTO": "when backdrop switches to %1",
  "EVENT_WHENGREATERTHAN": "when %1 > %2",
  "EVENT_WHENGREATERTHAN_TIMER": "timer",
  "EVENT_WHENGREATERTHAN_LOUDNESS": "loudness",
  "EVENT_BROADCAST": "broadcast %1",
  "EVENT_BROADCASTANDWAIT": "broadcast %1 and wait",
  "EVENT_WHENKEYPRESSED": "when %1 key pressed",
  "EVENT_WHENKEYPRESSED_SPACE": "space",
  "EVENT_WHENKEYPRESSED_LEFT": "left arrow",
  "EVENT_WHENKEYPRESSED_RIGHT": "right arrow",
  "EVENT_WHENKEYPRESSED_DOWN": "down arrow",
  "EVENT_WHENKEYPRESSED_UP": "up arrow",
  "EVENT_WHENKEYPRESSED_ANY": "any",
  "LOOKS_SAYFORSECS": "say %1 for %2 seconds",
  "LOOKS_SAY": "say %1",
  "LOOKS_HELLO": "Hello!",
  "LOOKS_THINKFORSECS": "think %1 for %2 seconds",
  "LOOKS_THINK": "think %1",
  "LOOKS_HMM": "Hmm...",
  "LOOKS_SHOW": "show",
  "LOOKS_HIDE": "hide",
  "LOOKS_HIDEALLSPRITES": "hide all sprites",
  "LOOKS_EFFECT_COLOR": "color",
  "LOOKS_EFFECT_FISHEYE": "fisheye",
  "LOOKS_EFFECT_WHIRL": "whirl",
  "LOOKS_EFFECT_PIXELATE": "pixelate",
  "LOOKS_EFFECT_MOSAIC": "mosaic",
  "LOOKS_EFFECT_BRIGHTNESS": "brightness",
  "LOOKS_EFFECT_GHOST": "ghost",
  "LOOKS_CHANGEEFFECTBY": "change %1 effect by %2",
  "LOOKS_SETEFFECTTO": "set %1 effect to %2",
  "LOOKS_CLEARGRAPHICEFFECTS": "clear graphic effects",
  "LOOKS_CHANGESIZEBY": "change size by %1",
  "LOOKS_SETSIZETO": "set size to %1 %",
  "LOOKS_SIZE": "size",
  "LOOKS_CHANGESTRETCHBY": "change stretch by %1",
  "LOOKS_SETSTRETCHTO": "set stretch to %1 %",
  "LOOKS_SWITCHCOSTUMETO": "switch costume to %1",
  "LOOKS_NEXTCOSTUME": "next costume",
  "LOOKS_SWITCHBACKDROPTO": "switch backdrop to %1",
  "LOOKS_GOTOFRONTBACK": "go to %1 layer",
  "LOOKS_GOTOFRONTBACK_FRONT": "front",
  "LOOKS_GOTOFRONTBACK_BACK": "back",
  "LOOKS_GOFORWARDBACKWARDLAYERS": "go %1 %2 layers",
  "LOOKS_GOFORWARDBACKWARDLAYERS_FORWARD": "forward",
  "LOOKS_GOFORWARDBACKWARDLAYERS_BACKWARD": "backward",
  "LOOKS_BACKDROPNUMBERNAME": "backdrop %1",
  "LOOKS_COSTUMENUMBERNAME": "costume %1",
  "LOOKS_NUMBERNAME_NUMBER": "number",
  "LOOKS_NUMBERNAME_NAME": "name",
  "LOOKS_SWITCHBACKDROPTOANDWAIT": "switch backdrop to %1 and wait",
  "LOOKS_NEXTBACKDROP_BLOCK": "next backdrop",
  "LOOKS_NEXTBACKDROP": "next backdrop",
  "LOOKS_PREVIOUSBACKDROP": "previous backdrop",
  "LOOKS_RANDOMBACKDROP": "random backdrop",
  "MOTION_MOVESTEPS": "move %1 steps",
  "MOTION_TURNLEFT": "turn %1 %2 degrees",
  "MOTION_TURNRIGHT": "turn %1 %2 degrees",
  "MOTION_POINTINDIRECTION": "point in direction %1",
  "MOTION_POINTTOWARDS": "point towards %1",
  "MOTION_POINTTOWARDS_POINTER": "mouse-pointer",
  "MOTION_POINTTOWARDS_RANDOM": "random direction",
  "MOTION_GOTO": "go to %1",
  "MOTION_GOTO_POINTER": "mouse-pointer",
  "MOTION_GOTO_RANDOM": "random position",
  "MOTION_GOTOXY": "go to x: %1 y: %2",
  "MOTION_GLIDESECSTOXY": "glide %1 secs to x: %2 y: %3",
  "MOTION_GLIDETO": "glide %1 secs to %2",
  "MOTION_GLIDETO_POINTER": "mouse-pointer",
  "MOTION_GLIDETO_RANDOM": "random position",
  "MOTION_CHANGEXBY": "change x by %1",
  "MOTION_SETX": "set x to %1",
  "MOTION_CHANGEYBY": "change y by %1",
  "MOTION_SETY": "set y to %1",
  "MOTION_IFONEDGEBOUNCE": "if on edge, bounce",
  "MOTION_SETROTATIONSTYLE": "set rotation style %1",
  "MOTION_SETROTATIONSTYLE_LEFTRIGHT": "left-right",
  "MOTION_SETROTATIONSTYLE_DONTROTATE": "don't rotate",
  "MOTION_SETROTATIONSTYLE_ALLAROUND": "all around",
  "MOTION_XPOSITION": "x position",
  "MOTION_YPOSITION": "y position",
  "MOTION_DIRECTION": "direction",
  "MOTION_SCROLLRIGHT": "scroll right %1",
  "MOTION_SCROLLUP": "scroll up %1",
  "MOTION_ALIGNSCENE": "align scene %1",
  "MOTION_ALIGNSCENE_BOTTOMLEFT": "bottom-left",
  "MOTION_ALIGNSCENE_BOTTOMRIGHT": "bottom-right",
  "MOTION_ALIGNSCENE_MIDDLE": "middle",
  "MOTION_ALIGNSCENE_TOPLEFT": "top-left",
  "MOTION_ALIGNSCENE_TOPRIGHT": "top-right",
  "MOTION_XSCROLL": "x scroll",
  "MOTION_YSCROLL": "y scroll",
  "MOTION_STAGE_SELECTED": "Stage selected: no motion blocks",
  "OPERATORS_ADD": "%1 + %2",
  "OPERATORS_SUBTRACT": "%1 - %2",
  "OPERATORS_MULTIPLY": "%1 * %2",
  "OPERATORS_DIVIDE": "%1 / %2",
  "OPERATORS_RANDOM": "pick random %1 to %2",
  "OPERATORS_GT": "%1 > %2",
  "OPERATORS_GT_EQUALS": "%1 >= %2",
  "OPERATORS_LT": "%1 < %2",
  "OPERATORS_LT_EQUALS": "%1 <= %2",
  "OPERATORS_EQUALS": "%1 = %2",
  "OPERATORS_AND": "%1 and %2",
  "OPERATORS_OR": "%1 or %2",
  "OPERATORS_NOT": "not %1",
  "OPERATORS_JOIN": "join %1 %2",
  "OPERATORS_JOIN_APPLE": "apple",
  "OPERATORS_JOIN_BANANA": "banana",
  "OPERATORS_LETTEROF": "letter %1 of %2",
  "OPERATORS_LETTEROF_APPLE": "a",
  "OPERATORS_LENGTH": "length of %1",
  "OPERATORS_CONTAINS": "%1 contains %2?",
  "OPERATORS_MOD": "%1 mod %2",
  "OPERATORS_ROUND": "round %1",
  "OPERATORS_MATHOP": "%1 of %2",
  "OPERATORS_MATHOP_ABS": "abs",
  "OPERATORS_MATHOP_FLOOR": "floor",
  "OPERATORS_MATHOP_CEILING": "ceiling",
  "OPERATORS_MATHOP_SQRT": "sqrt",
  "OPERATORS_MATHOP_SIN": "sin",
  "OPERATORS_MATHOP_COS": "cos",
  "OPERATORS_MATHOP_TAN": "tan",
  "OPERATORS_MATHOP_ASIN": "asin",
  "OPERATORS_MATHOP_ACOS": "acos",
  "OPERATORS_MATHOP_ATAN": "atan",
  "OPERATORS_MATHOP_LN": "ln",
  "OPERATORS_MATHOP_LOG": "log",
  "OPERATORS_MATHOP_EEXP": "e ^",
  "OPERATORS_MATHOP_10EXP": "10 ^",
  "PROCEDURES_DEFINITION": "define %1",
  "SENSING_TOUCHINGOBJECT": "touching %1?",
  "SENSING_TOUCHINGOBJECT_POINTER": "mouse-pointer",
  "SENSING_TOUCHINGOBJECT_EDGE": "edge",
  "SENSING_TOUCHINGCOLOR": "touching color %1?",
  "SENSING_COLORISTOUCHINGCOLOR": "color %1 is touching %2?",
  "SENSING_DISTANCETO": "distance to %1",
  "SENSING_DISTANCETO_POINTER": "mouse-pointer",
  "SENSING_ASKANDWAIT": "ask %1 and wait",
  "SENSING_ASK_TEXT": "What's your name?",
  "SENSING_ANSWER": "answer",
  "SENSING_KEYPRESSED": "key %1 pressed?",
  "SENSING_MOUSEDOWN": "mouse down?",
  "SENSING_MOUSEX": "mouse x",
  "SENSING_MOUSEY": "mouse y",
  "SENSING_SETDRAGMODE": "set drag mode %1",
  "SENSING_SETDRAGMODE_DRAGGABLE": "draggable",
  "SENSING_SETDRAGMODE_NOTDRAGGABLE": "not draggable",
  "SENSING_LOUDNESS": "loudness",
  "SENSING_LOUD": "loud?",
  "SENSING_TIMER": "timer",
  "SENSING_RESETTIMER": "reset timer",
  "SENSING_OF": "%1 of %2",
  "SENSING_OF_XPOSITION": "x position",
  "SENSING_OF_YPOSITION": "y position",
  "SENSING_OF_DIRECTION": "direction",
  "SENSING_OF_COSTUMENUMBER": "costume #",
  "SENSING_OF_COSTUMENAME": "costume name",
  "SENSING_OF_SIZE": "size",
  "SENSING_OF_VOLUME": "volume",
  "SENSING_OF_BACKDROPNUMBER": "backdrop #",
  "SENSING_OF_BACKDROPNAME": "backdrop name",
  "SENSING_OF_STAGE": "Stage",
  "SENSING_CURRENT": "current %1",
  "SENSING_CURRENT_YEAR": "year",
  "SENSING_CURRENT_MONTH": "month",
  "SENSING_CURRENT_DATE": "date",
  "SENSING_CURRENT_DAYOFWEEK": "day of week",
  "SENSING_CURRENT_HOUR": "hour",
  "SENSING_CURRENT_MINUTE": "minute",
  "SENSING_CURRENT_SECOND": "second",
  "SENSING_DAYSSINCE2000": "days since 2000",
  "SENSING_USERNAME": "username",
  "SENSING_USERID": "user id",
  "SOUND_PLAY": "start sound %1",
  "SOUND_PLAYUNTILDONE": "play sound %1 until done",
  "SOUND_STOPALLSOUNDS": "stop all sounds",
  "SOUND_SETEFFECTO": "set %1 effect to %2",
  "SOUND_CHANGEEFFECTBY": "change %1 effect by %2",
  "SOUND_CLEAREFFECTS": "clear sound effects",
  "SOUND_EFFECTS_PITCH": "pitch",
  "SOUND_EFFECTS_PAN": "pan left/right",
  "SOUND_CHANGEVOLUMEBY": "change volume by %1",
  "SOUND_SETVOLUMETO": "set volume to %1%",
  "SOUND_VOLUME": "volume",
  "SOUND_RECORD": "record...",
  "CATEGORY_MOTION": "Motion",
  "CATEGORY_LOOKS": "Looks",
  "CATEGORY_SOUND": "Sound",
  "CATEGORY_EVENTS": "Events",
  "CATEGORY_CONTROL": "Control",
  "CATEGORY_SENSING": "Sensing",
  "CATEGORY_OPERATORS": "Operators",
  "CATEGORY_VARIABLES": "Variables",
  "CATEGORY_MYBLOCKS": "My Blocks",
  "DUPLICATE": "Duplicate",
  "DELETE": "Delete",
  "ADD_COMMENT": "Add Comment",
  "REMOVE_COMMENT": "Remove Comment",
  "DELETE_BLOCK": "Delete Block",
  "DELETE_X_BLOCKS": "Delete %1 Blocks",
  "DELETE_ALL_BLOCKS": "Delete all %1 blocks?",
  "CLEAN_UP": "Clean up Blocks",
  "HELP": "Help",
  "UNDO": "Undo",
  "REDO": "Redo",
  "EDIT_PROCEDURE": "Edit",
  "SHOW_PROCEDURE_DEFINITION": "Go to definition",
  "WORKSPACE_COMMENT_DEFAULT_TEXT": "Say something...",
  "COLOUR_HUE_LABEL": "Color",
  "COLOUR_SATURATION_LABEL": "Saturation",
  "COLOUR_BRIGHTNESS_LABEL": "Brightness",
  "CHANGE_VALUE_TITLE": "Change value:",
  "RENAME_VARIABLE": "Rename variable",
  "RENAME_VARIABLE_STRING": "Rename string variable",
  "RENAME_VARIABLE_NUMBER": "Rename number variable",
  "RENAME_VARIABLE_LIST": "Rename list variable",
  "RENAME_VARIABLE_TITLE": "Rename all \"%1\" variables to:",
  "RENAME_VARIABLE_MODAL_TITLE": "Rename Variable",
  "NEW_VARIABLE": "Make a Variable",
  "NEW_VARIABLE_TITLE": "New variable name:",
  "VARIABLE_MODAL_TITLE": "New Variable",
  "VARIABLE_ALREADY_EXISTS": "A variable named \"%1\" already exists.",
  "VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE": "A variable named \"%1\" already exists for another variable of type \"%2\".",
  "DELETE_VARIABLE_CONFIRMATION": "Delete %1 uses of the \"%2\" variable?",
  "CANNOT_DELETE_VARIABLE_PROCEDURE": "Can't delete the variable \"%1\" because it's part of the definition of the function \"%2\"",
  "DELETE_VARIABLE": "Delete the \"%1\" variable",
  "DELETE_VARIABLE_STRING": "Delete the \"%1\" string variable",
  "DELETE_VARIABLE_NUMBER": "Delete the \"%1\" number variable",
  "DELETE_VARIABLE_LIST": "Delete the \"%1\" list variable",
  "NEW_PROCEDURE": "Make a Block",
  "PROCEDURE_ALREADY_EXISTS": "A procedure named \"%1\" already exists.",
  "PROCEDURE_DEFAULT_NAME": "block name",
  "NEW_LIST": "Make a List",
  "NEW_LIST_TITLE": "New list name:",
  "LIST_MODAL_TITLE": "New List",
  "LIST_ALREADY_EXISTS": "A list named \"%1\" already exists.",
  "RENAME_LIST_TITLE": "Rename all \"%1\" lists to:",
  "RENAME_LIST_MODAL_TITLE": "Rename List",
  "DEFAULT_LIST_ITEM": "thing",
  "DELETE_LIST": "Delete the \"%1\" list",
  "RENAME_LIST": "Rename list",
  "NEW_BROADCAST_MESSAGE": "New message",
  "NEW_BROADCAST_MESSAGE_TITLE": "New message name:",
  "BROADCAST_MODAL_TITLE": "New Message",
  "DEFAULT_BROADCAST_MESSAGE_NAME": "message1",
  "DELETE_PROCEDURE": "To delete a block definition, first remove all uses of the block",
  "OK": "OK",
  "PROMPT": "Prompt",
  "STOP": "stop",
  "STRING_TYPE": "string",
  "NEW_STRING": "Create string...",
  "WIFI": "WIFI",
  "USERNAME": "user name",
  "PWD": "password",
  "IP": "IP Address",
  "IOT_SERVICE": "IOT Service",
  "IOT_ID": "iot id",
  "IOT_PWD": "iot pwd",
  "IOT_TOPIC": "Topic_0",
  "MORE_TOPIC": "Topic",
  "OBLOQ_HTTP": "HTTP",
  "OBLOQ_IP": "IP Address",
  "OBLOQ_PORT": "Port",
  "ADD_TOPIC": "Add Topic, only add up to 4",
  "DELETE_TOPIC": "Delete Topic",
  "IOT_SERVER": "Server",
  "CHINA": "China",
  "GLOBAL": "Global",
  "LOW_C": "Low C/C3",
  "LOW_C$": "Low C#/C#3",
  "LOW_D": "Low D/D3",
  "LOW_D$": "Low D#/D#3",
  "LOW_E": "Low E/E3",
  "LOW_F": "Low F/F3",
  "LOW_F$": "Low F#/F#3",
  "LOW_G": "Low G/G3",
  "LOW_G$": "Low G#/G#3",
  "LOW_A": "Low A/A3",
  "LOW_A$": "Low A#/A#3",
  "LOW_B": "Low B/B3",
  "MIDDLE_C": "Middle C/C4",
  "MIDDLE_C$": "Middle C#/C#4",
  "MIDDLE_D": "Middle D/D4",
  "MIDDLE_D$": "Middle D#/D#4",
  "MIDDLE_E": "Middle E/E4",
  "MIDDLE_F": "Middle F/F4",
  "MIDDLE_F$": "Middle F#/F#4",
  "MIDDLE_G": "Middle G/G4",
  "MIDDLE_G$": "Middle G#/G#4",
  "MIDDLE_A": "Middle A/A4",
  "MIDDLE_A$": "Middle A#/A#4",
  "MIDDLE_B": "Middle B/B4",
  "HIGH_C": "High C/C5",
  "HIGH_C$": "High C#/C#5",
  "HIGH_D": "High D/D5",
  "HIGH_D$": "High D#/D#5",
  "HIGH_E": "High E/E5",
  "HIGH_F": "High F/F5",
  "HIGH_F$": "High F#/F#5",
  "HIGH_G": "High G/G5",
  "HIGH_G$": "High G#/G#5",
  "HIGH_A": "High A/A5",
  "HIGH_A$": "High A#/A#5",
  "HIGH_B": "High B/B5",
  "OPERATORS_STRINGTONUMBER": "convert string %1 to %2",
  "INTEGER": "Integer",
  "DECIMAL": "Decimal",
  "OPERATORS_NUMTOASCII": "convert number %1 to ASCII",
  "OPERATORS_STRINGTOASCII": "convert string %1 to ASCII",
  "OPERATORS_NUMTOSTRING": "convert number %1 to string",
  "OPERATORS_MAP": "map %1 from[ %2 , %3 ] to[ %4 , %5 ]",
  "OPERATORS_CONSTRAIN": "constrain %1 between(min) %2 and(max) %3",
  "STEPS_PER_TURN": "steps per turn",
  "ROTATE_SPEED": "rotate speed(r/s)",
  "NEW_AI": "Make an AI Block",
  "ASK_AND_PAINT": "please draw a number",
  "GET_IDENTIFIED_NUM": "number recognized",
  "READ_NUM_AND_SAY": "speak out a number recognized",
  "IMAGE_ADDR": "Image Addr",
  "IMAGE_PREVIEW": "Image Preview",
  "IMAGE_OPEN": "open",
  "IMAGE_SIZE": "Image Size",
  "IMAGE_WIDTH": "W",
  "IMAGE_HEIGHT": "H",
  "VARIABLE": "1%",
  "VARIABLE_LIST": "1%"
};

