import { commandWs } from "./websocket/commandWs";
import { MakeDirectoryOptions, RmDirOptions } from "fs";

export type ReaddirOptionsType =
  | {
    encoding: BufferEncoding | null;
    withFileTypes?: false | undefined;
    recursive?: boolean | undefined;
  }
  | BufferEncoding
  | undefined
  | null

export function readFile(path: string, options?: {
  encoding: string
}): Promise<any> {
  return commandWs.sendRemoteRequest("readFile", { path, encoding: options?.encoding })
}

export function writeFile(fileName: string, content: string | ArrayBufferView) {
  return commandWs.sendRemoteRequest("writeFile", { fileName, content })
}

export function readdir(path: string, options: ReaddirOptionsType) {
  return commandWs.sendRemoteRequest("readdir", { path, options })
}

export function mkdir(path: string, options?: MakeDirectoryOptions & {
  recursive: true;
}) {
  return commandWs.sendRemoteRequest("mkdir", { path, options })
}

export function unlink(path: string) {
  return commandWs.sendRemoteRequest("unlink", { path })
}

export function rmdir(path: string, options: RmDirOptions) {
  return commandWs.sendRemoteRequest("rmdir", { path, options })
}

export function access(path: string) {
  return commandWs.sendRemoteRequest("access", { path })
}



export function exists(path: string) {
  return commandWs.sendRemoteRequest("exists", { path })
}



export function writeImgFile(fileName: string, content: string) {
  return commandWs.sendRemoteRequest("writeImgFile", { fileName, content })
}


export default {
  readFile,
  writeFile,
  readdir,
  mkdir,
  unlink,
  rmdir,
  access,
  exists,
};
