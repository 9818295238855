const TOKEN_KEY = 'Access_JWT::token';
// token
let _token = window.localStorage.getItem(TOKEN_KEY) ;

// 获取token
export function getToken() {
    if (!_token) return ; 
    return _token;
}

export function setToken(token:string) {
    _token = token;
}

// 存储token
export function saveToken(token:string) {
    _token = token;
    window.localStorage.setItem(TOKEN_KEY, token);
}

// 清除用户信息
export function clearLocalStorage() {
    _token = null;
    window.localStorage.removeItem(TOKEN_KEY);
}
