import DFIcon from "component/df-icon/DFIcon";
import ScratchBlocks from "lib/scratch-blocks";
import ScreenShots from "lib/screenshots";
import { MutableRefObject } from "react";
import { useSelector } from "react-redux";
import { selectProject } from "redux/project/projectSlice";
import styles from "./WorkspaceControl.module.scss";

// workspace上的控制按钮（重做、撤销、截图）
export const WorkspaceControl = (props: { workspaceRef: MutableRefObject<any> }) => {
  const { projectTitle } = useSelector(selectProject)
  const { workspaceRef } = props;
  const handleUndo = () => {
    workspaceRef.current?.undo(false);
    // 修复删除自定义block事件头，撤销，toolbox不刷新的问题
    const selectedItem = workspaceRef.current?.toolbox_.getSelectedItem();
    const selectedItemId = selectedItem ? selectedItem.id_ : null;
    if (selectedItemId === 'myBlocks') {
      workspaceRef.current.toolbox_.clearSelection();
      workspaceRef.current.toolbox_.setNotSearchSelectedItem(selectedItemId);
    }
  };
  const handleRedo = () => {
    workspaceRef.current.undo(true);
  };

  const whenKeyScreenshots = () => {
    ScreenShots.screenShots(workspaceRef.current, projectTitle)
  };

  return (
    <div className={styles.container} title={ScratchBlocks.Msg.UNDO}>
      <span onClick={handleUndo}>
        <DFIcon name="undo" size={14} />
      </span>
      <span onClick={handleRedo} title={ScratchBlocks.Msg.REDO}>
        <DFIcon name="redo" size={14} />
      </span>
      {/* ScratchBlocks.Msg.SNAPSHOT */}
      <span onClick={whenKeyScreenshots} title={"截图"}>
        <DFIcon name="screenshot" /*iconsizeStyle={{ fontWeight: "bold"}}*/ size={18.135} />
      </span>
    </div>
  );
};
