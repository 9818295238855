import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { FormattedMessage } from "react-intl";
import { ContextMenuTrigger } from "lib/react-contextmenu/modules";
import {
  BorderedMenuItem,
  ContextMenu,
  MenuItem,
} from "component/context-menu/context-menu.jsx";
import Box from "component/box/box.jsx";
import DefaultMonitor from "./default-monitor.jsx";
import LargeMonitor from "./large-monitor.jsx";
import SliderMonitor from "./slider-monitor.jsx";
import ListMonitor from "./list-monitor.jsx";
import SpeechRecordMonitor from "./speech-record-monitor.jsx";
import sonogramMonitor from "./sonogram.jsx";
import styles from "./monitor.module.scss";
import classNames from "classnames";

const categories = {
  data: "#FF8C1A",
  sensing: "#5CB1D6",
  sound: "#CF63CF",
  looks: "#9966FF",
  motion: "#4C97FF",
  list: "#FC662C",
  extension: "#0FBD8C",
};

const modes = {
  default: DefaultMonitor,
  large: LargeMonitor,
  slider: SliderMonitor,
  list: ListMonitor,
  speechRecord: SpeechRecordMonitor,
  sonogram: sonogramMonitor,
};

const MonitorComponent = (props) => {
  return (
    <div>
      <ContextMenuTrigger
        disable={!props.draggable}
        holdToDisplay={props.mode === "slider" ? -1 : 1000}
        id={`monitor-${props.label}`}
      >
        <Draggable
          bounds=".monitor-overlay" // Class for monitor container
          cancel=".no-drag" // Class used for slider input to prevent drag
          defaultClassNameDragging={classNames({
            [styles.dragging]:
              props.mode !== "speechRecord" && props.mode !== "sonogram",
          })}
          disabled={!props.draggable}
          onStop={props.onDragEnd}
        >
          <Box
            className={classNames(styles.monitorContainer, {
              [styles.monitorContainerOther]:
                props.mode === "speechRecord" || props.mode === "sonogram",
            })}
            componentRef={props.componentRef}
            onDoubleClick={
              props.mode === "list" ||
                props.mode === "speechRecord" ||
                props.mode === "sonogram" ||
                !props.draggable
                ? null
                : props.onNextMode
            }
          >
            {React.createElement(modes[props.mode], {
              categoryColor:props.categoryPrimaryColor||
                categories[props.category] || categories["extension"],
              ...props,
            })}
          </Box>
        </Draggable>
      </ContextMenuTrigger>
      {props.mode === "speechRecord" || props.mode === "sonogram"
        ? null
        : ReactDOM.createPortal(
          <ContextMenu id={`monitor-${props.label}`}>
            {props.onSetModeToDefault && (
              <MenuItem
                onClick={props.onSetModeToDefault}
                theme={props.theme}
              >
                <FormattedMessage
                  defaultMessage="normal readout"
                  description="Menu item to switch to the default monitor"
                  id="gui.monitor.contextMenu.default"
                />
              </MenuItem>
            )}
            {props.onSetModeToLarge && (
              <MenuItem onClick={props.onSetModeToLarge} theme={props.theme}>
                <FormattedMessage
                  defaultMessage="large readout"
                  description="Menu item to switch to the large monitor"
                  id="gui.monitor.contextMenu.large"
                />
              </MenuItem>
            )}
            {props.onSetModeToSlider && (
              <MenuItem onClick={props.onSetModeToSlider} theme={props.theme}>
                <FormattedMessage
                  defaultMessage="slider"
                  description="Menu item to switch to the slider monitor"
                  id="gui.monitor.contextMenu.slider"
                />
              </MenuItem>
            )}
            {props.onSliderPromptOpen && props.mode === "slider" && (
              <BorderedMenuItem
                theme={props.theme}
                onClick={props.onSliderPromptOpen}
              >
                <FormattedMessage
                  defaultMessage="change slider range"
                  description="Menu item to change the slider range"
                  id="gui.monitor.contextMenu.sliderRange"
                />
              </BorderedMenuItem>
            )}
            {props.onImport && (
              <MenuItem onClick={props.onImport} theme={props.theme}>
                <FormattedMessage
                  defaultMessage="import"
                  description="Menu item to import into list monitors"
                  id="gui.monitor.contextMenu.import"
                />
              </MenuItem>
            )}
            {props.onExport && (
              <MenuItem onClick={props.onExport} theme={props.theme}>
                <FormattedMessage
                  defaultMessage="export"
                  description="Menu item to export from list monitors"
                  id="gui.monitor.contextMenu.export"
                />
              </MenuItem>
            )}
          </ContextMenu>,
          document.body
        )}
    </div>
  );
};
MonitorComponent.categories = categories;

const monitorModes = Object.keys(modes);

MonitorComponent.propTypes = {
  category: PropTypes.oneOf(Object.keys(categories)),
  componentRef: PropTypes.func.isRequired,
  draggable: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(monitorModes),
  onDragEnd: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  onImport: PropTypes.func,
  onHide: PropTypes.func,
  onNextMode: PropTypes.func.isRequired,
  onSetModeToDefault: PropTypes.func,
  onSetModeToLarge: PropTypes.func,
  onSetModeToSlider: PropTypes.func,
  onSliderPromptOpen: PropTypes.func,
};

MonitorComponent.defaultProps = {
  category: "extension",
  mode: "default",
};

export { MonitorComponent as default, monitorModes };
