import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

interface SerialWriteSettingState {
    lineNew: '' | '\n' | '\r' | '\r\n'
    showHex: boolean
    isScroll: boolean
}

const initialState: SerialWriteSettingState = {
    showHex: false,
    isScroll: true,
    lineNew: '',
}

export const serialWriteSettingSlice = createSlice({
    name: 'serialWriteSetting',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        updateLineNew: (state, action: PayloadAction<'' | '\n' | '\r' | '\r\n'>) => {
            state.lineNew = action.payload
        },
        updateIsScroll: (state, action: PayloadAction<boolean>) => {
            state.isScroll = action.payload
        },
        updateShowHex: (state, action: PayloadAction<boolean>) => {
            state.showHex = action.payload
        },
    },
})

export const {updateLineNew, updateIsScroll, updateShowHex} = serialWriteSettingSlice.actions;
export const selectSerialWriteSetting = (state: RootState) => state.serialWriteSetting
export default serialWriteSettingSlice.reducer
