import * as events from "events";
import MqttWs from "./websocket/mqttWs";

export default class Mqtt extends events.EventEmitter {
  private mqttWs: MqttWs;
  wsOpened: boolean = false;
  constructor() {
    super();
    this.mqttWs = new MqttWs();
    this.mqttWs.on("wsOpen", this._onWsOpen.bind(this));
    this.mqttWs.on("connect", () => this.emit("connect"))
    this.mqttWs.on("mqtt_error", (e) => this.emit("mqtt_error", e));
    this.mqttWs.on("message", (...args) => this.emit("message", ...args));
    this.mqttWs.on("offline", (...args) => this.emit("offline", ...args));
    this.mqttWs.on("close", (...args) => this.emit("close", ...args));
  }

  get isOpen() {
    return this.mqttWs.isOpen() || false;
  }

  _onWsOpen() {
    this.wsOpened = true;
    this.emit("wsOpened");
  }

  waitCreated() {
    if (this.wsOpened) {
      return Promise.resolve();
    } else {
      return new Promise<void>((resolve, reject) => {
        this.once("wsOpened", () => {
          resolve();
        });
      });
    }
  }

  connect(url: string, options: any) {
    this.waitCreated().then(() => {
      this.mqttWs.connect(url, options);
    }).catch(() => {
      console.log("mqtt connect error");
    });
  }

  subscribe(topic: string, callback: any) {
    this.waitCreated().then(() => {
      this.mqttWs.subscribe(topic).then((args: any) => {
        callback && callback(args);
      })
    });
  }


  publish(topic: string, message: any, options: any, callback: any) {
    this.waitCreated().then(() => {
      this.mqttWs.publish(topic, message, options).then((args: any) => {
        callback && callback(args);
      });
    });
  }

  end() {
    this.waitCreated().then(() => {
      this.mqttWs.end();
    })
  }
}
