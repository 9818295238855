import {ExtensionConfig, ExternalExtensionDataType} from "./type";
import {getBuiltinExtensionPath, getLocalExtensionPath} from "../../path/assetPath";
import path from "path";

// 获取extension的唯一ID
export const getExtensionID = (extensionData: {author: string, id: string}) => {
    return `ext-${extensionData.author}-${extensionData.id}`;
}

export const getExtensionIDWithVersion = (extensionData: {author: string, id: string, version: string}) => {
    return `ext-${extensionData.author}-${extensionData.id}@${extensionData.version||'0.0.1'}`;
}

// 获取翻译之后的name和description
export const getNameOrDescription = (data: {[locale: string]: string}|string, locale: string) => {
    if (typeof data === 'string') return data;
    return data[locale]||'';
}
