const Cast = require('../util/cast.js');
const MathUtil = require('../util/math-util.js');

function getOpCode (op, num, order1, order2) {
    const args = [];
    switch (op) {
    case 'abs':
        args[0] = `abs(${num})`;
        args[1] = order1;
        break;
    case 'floor':
        args[0] = `floor(${num})`;
        args[1] = order1;
        break;
    case 'ceiling':
        args[0] = `ceil(${num})`;
        args[1] = order1;
        break;
    case 'sqrt':
        args[0] = `sqrt(${num})`;
        args[1] = order1;
        break;
    case 'sin':
        args[0] = `sin((float)${num} / 180 * PI)`;
        args[1] = order1;
        break;
    case 'cos':
        args[0] = `cos((float)${num} / 180 * PI)`;
        args[1] = order1;
        break;
    case 'tan':
        args[0] = `tan((float)${num} / 180 * PI)`;
        args[1] = order1;
        break;
    case 'asin':
        args[0] = `asin(${num}) / PI * 180`;
        args[1] = order2;
        break;
    case 'acos':
        args[0] = `acos(${num}) / PI * 180`;
        args[1] = order2;
        break;
    case 'atan':
        args[0] = `atan(${num}) / PI * 180`;
        args[1] = order2;
        break;
    case 'ln':
        args[0] = `log(${num})`;
        args[1] = order1;
        break;
    case 'log':
        args[0] = `log10(${num})`;
        args[1] = order2;
        break;
    case 'e ^':
        args[0] = `exp(${num})`;
        args[1] = order1;
        break;
    case '10 ^':
        args[0] = `pow(10, ${num})`;
        args[1] = order1;
        break;
    default:
        args[0] = `abs(${num})`;
        args[1] = order1;
        break;
    }
    return args;
}
function getOpParameters (p1, p2, type1, type2) {
    if (type1 === 'text') {
        p1 = p1.substr(1, p1.length - 2);
        p1 = p1 === '' ? 0 : p1;
        if (!isNaN(Number(p1))) {
            p1 = Number(p1);
        } else if (p1[0] === '\\' && p1[p1.length - 1] === '"') {
            p1 = `${p1.substr(1, p1.length - 3)}"`;
        } else {
            p1 = `"${p1}"`;
        }
    }
    if (type2 === 'text') {
        p2 = p2.substr(1, p2.length - 2);
        p2 = p2 === '' ? 0 : p2;
        if (!isNaN(Number(p2))) {
            p2 = Number(p2);
        } else if (p2[0] === '\\' && p2[p2.length - 1] === '"') {
            p2 = `${p2.substr(1, p2.length - 3)}"`;
        } else {
            p2 = `"${p2}"`;
        }
    }
    return [p1, p2];
}
function getOpParameters2 (p1, p2, type1, type2) {
    if (type1 === 'text') {
        p1 = p1.substr(1, p1.length - 2);
        p1 = p1 === '' ? 0 : p1;
        if (!isNaN(Number(p1))) {
            p1 = Number(p1);
        } else if (p1[0] === '\\' && p1[p1.length - 1] === '"') {
            p1 = `${p1.substr(1, p1.length - 3)}"`;
        } else {
            p1 = `String("${p1}")`;
        }
    }
    if (type2 === 'text') {
        p2 = p2.substr(1, p2.length - 2);
        p2 = p2 === '' ? 0 : p2;
        if (!isNaN(Number(p2))) {
            p2 = Number(p2);
        } else if (p2[0] === '\\' && p2[p2.length - 1] === '"') {
            p2 = `${p2.substr(1, p2.length - 3)}"`;
        } else {
            p2 = `String("${p2}")`;
        }
    }
    return [p1, p2];
}

const operatorsCode = {
    operator_add (generator, block, params){
        return [`${params.NUM1.code} + ${params.NUM2.code}`, generator.ORDER_ADDITIVE];
    },
    operator_subtract (generator, block, params){
        return [`${params.NUM1.code} - ${params.NUM2.code}`, generator.ORDER_ADDITIVE];
    },
    operator_multiply (generator, block, params){
        return [`${params.NUM1.code} * ${params.NUM2.code}`, generator.ORDER_ADDITIVE];
    },
    operator_divide (generator, block, params){
        return [`${params.NUM1.code} / ${params.NUM2.code}`, generator.ORDER_ADDITIVE];
    },
    operator_random (generator, block, params){
        const code = `random(${params.FROM.code}, ${params.TO.code}+1)`;
        generator.addSetupMainTop(`dfrobotRandomSeed`, 'dfrobotRandomSeed();');
        return [code, generator.ORDER_UNARY_POSTFIX];
    },
    operator_lt (generator, block, params){
        const p1 = params.OPERAND1.code;
        const p2 = params.OPERAND2.code;
        const par = getOpParameters(p1, p2, params.OPERAND1.parType, params.OPERAND2.parType);
        const code = `${par[0]}<${par[1]}`;
        return [code, generator.ORDER_RELATIONAL];
    },
    operator_equals (generator, block, params){
        const p1 = params.OPERAND1.code;
        const p2 = params.OPERAND2.code;
        const par = getOpParameters2(p1, p2, params.OPERAND1.parType, params.OPERAND2.parType);
        const code = `${par[0]}==${par[1]}`;
        return [code, generator.ORDER_EQUALITY];
    },
    operator_gt (generator, block, params){
        const p1 = params.OPERAND1.code;
        const p2 = params.OPERAND2.code;
        const par = getOpParameters(p1, p2, params.OPERAND1.parType, params.OPERAND2.parType);
        const code = `${par[0]}>${par[1]}`;
        return [code, generator.ORDER_RELATIONAL];
    },
    operator_and (generator, block, params){
        const code = `${params.OPERAND1.code} && ${params.OPERAND2.code}`;
        return [code, generator.ORDER_LOGICAL_AND];
    },
    operator_not (generator, block, params){
        const code = `!${params.OPERAND.code}`;
        return [code, generator.ORDER_UNARY_PREFIX];
    },
    operator_join (generator, block, params){
        const code = `String(${params.STRING1.code}) + String(${params.STRING2.code})`;
        return [code, generator.ORDER_UNARY_POSTFIX];
    },
    operator_letter_of (generator, block, params){
        const code = `String(String(${params.STRING.code}).charAt(${params.LETTER.code}-1))`;
        return [code, generator.ORDER_UNARY_POSTFIX];
    },
    operator_length (generator, block, params){
        const code = `String(${params.STRING.code}).length()`;
        return [code, generator.ORDER_UNARY_POSTFIX];
    },
    operator_contains (generator, block, params){
        const code = `(String(${params.STRING1.code}).indexOf(String(${params.STRING2.code})) != -1)`;
        return [code, generator.ORDER_UNARY_POSTFIX];
    },
    operator_mod (generator, block, params){
        const code = `(int32_t(${params.NUM1.code})) % (int32_t(${params.NUM2.code}))`;
        return [code, generator.ORDER_MULTIPLICATIVE];
    },
    operator_round (generator, block, params){
        const code = `round(${params.NUM.code})`;
        return [code, generator.ORDER_UNARY_POSTFIX];
    },
    operator_mathop (generator, block, params){
        const operator = params.OPERATOR.code;
        const num = params.NUM.code;
        const args = getOpCode(operator, num, generator.ORDER_UNARY_POSTFIX, generator.ORDER_MULTIPLICATIVE);
        return [args[0], args[1]];
    }
};

class Scratch3OperatorsBlocks {
    constructor (runtime) {
        /**
         * The runtime instantiating this block package.
         * @type {Runtime}
         */
        this.runtime = runtime;
    }

    /**
     * Retrieve the block primitives implemented by this package.
     * @return {object.<string, Function>} Mapping of opcode to Function.
     */
    getPrimitives () {
        return {
            operator_add: this.add,
            operator_subtract: this.subtract,
            operator_multiply: this.multiply,
            operator_divide: this.divide,
            operator_lt: this.lt,
            operator_equals: this.equals,
            operator_gt: this.gt,
            operator_and: this.and,
            operator_or: this.or,
            operator_not: this.not,
            operator_random: this.random,
            operator_join: this.join,
            operator_letter_of: this.letterOf,
            operator_length: this.length,
            operator_contains: this.contains,
            operator_mod: this.mod,
            operator_round: this.round,
            operator_mathop: this.mathop,
            operator_textsrobotseven: this.textsrobotseven,
            operator_textsrobotsevenNew: this.textsrobotsevenNew,
            operator_map: this.map,
            operator_constrain: this.constrain
        };
    }

    getCodePrimitives () {
        return operatorsCode;
    }

    add (args) {
        return Cast.toNumber(args.NUM1) + Cast.toNumber(args.NUM2);
    }

    subtract (args) {
        return Cast.toNumber(args.NUM1) - Cast.toNumber(args.NUM2);
    }

    multiply (args) {
        return Cast.toNumber(args.NUM1) * Cast.toNumber(args.NUM2);
    }

    divide (args) {
        return Cast.toNumber(args.NUM1) / Cast.toNumber(args.NUM2);
    }

    lt (args) {
        return Cast.compare(args.OPERAND1, args.OPERAND2) < 0;
    }

    equals (args) {
        return Cast.compare(args.OPERAND1, args.OPERAND2) === 0;
    }

    gt (args) {
        return Cast.compare(args.OPERAND1, args.OPERAND2) > 0;
    }

    and (args) {
        return Cast.toBoolean(args.OPERAND1) && Cast.toBoolean(args.OPERAND2);
    }

    or (args) {
        return Cast.toBoolean(args.OPERAND1) || Cast.toBoolean(args.OPERAND2);
    }

    not (args) {
        return !Cast.toBoolean(args.OPERAND);
    }

    random (args) {
        const nFrom = Cast.toNumber(args.FROM);
        const nTo = Cast.toNumber(args.TO);
        const low = nFrom <= nTo ? nFrom : nTo;
        const high = nFrom <= nTo ? nTo : nFrom;
        if (low === high) return low;
        // If both arguments are ints, truncate the result to an int.
        if (Cast.isInt(args.FROM) && Cast.isInt(args.TO)) {
            return low + Math.floor(Math.random() * ((high + 1) - low));
        }
        return (Math.random() * (high - low)) + low;
    }

    join (args) {
        return Cast.toString(args.STRING1) + Cast.toString(args.STRING2);
    }

    letterOf (args) {
        const index = Cast.toNumber(args.LETTER) - 1;
        const str = Cast.toString(args.STRING);
        // Out of bounds?
        if (index < 0 || index >= str.length) {
            return '';
        }
        return str.charAt(index);
    }

    length (args) {
        return Cast.toString(args.STRING).length;
    }

    contains (args) {
        const format = function (string) {
            return Cast.toString(string).toLowerCase();
        };
        return format(args.STRING1).includes(format(args.STRING2));
    }

    mod (args) {
        const n = Cast.toNumber(args.NUM1);
        const modulus = Cast.toNumber(args.NUM2);
        let result = n % modulus;
        // Scratch mod uses floored division instead of truncated division.
        if (result / modulus < 0) result += modulus;
        return result;
    }

    round (args) {
        return Math.round(Cast.toNumber(args.NUM));
    }

    mathop (args) {
        const operator = Cast.toString(args.OPERATOR).toLowerCase();
        const n = Cast.toNumber(args.NUM);
        switch (operator) {
        case 'abs': return Math.abs(n);
        case 'floor': return Math.floor(n);
        case 'ceiling': return Math.ceil(n);
        case 'sqrt': return Math.sqrt(n);
        case 'sin': return parseFloat(Math.sin((Math.PI * n) / 180).toFixed(10));
        case 'cos': return parseFloat(Math.cos((Math.PI * n) / 180).toFixed(10));
        case 'tan': return MathUtil.tan(n);
        case 'asin': return (Math.asin(n) * 180) / Math.PI;
        case 'acos': return (Math.acos(n) * 180) / Math.PI;
        case 'atan': return (Math.atan(n) * 180) / Math.PI;
        case 'ln': return Math.log(n);
        case 'log': return Math.log(n) / Math.LN10;
        case 'e ^': return Math.exp(n);
        case '10 ^': return Math.pow(10, n);
        }
        return 0;
    }

    textsrobotseven (args) {
        const [inputValue, inputIndexStart, inputIndexEnd, list, list1] = [args.STRING1, args.STRING2, args.STRING3, args.LIST1, args.LIST2];
        const [inputIndexStartNum, inputIndexEndNum] = [parseInt(inputIndexStart), parseInt(inputIndexEnd)];
        let comeBackValue;
        if (list === 'first' && list1 === 'first') {
            comeBackValue = inputValue.substring(inputIndexStartNum - 1, inputIndexEndNum);
        } else if (list === 'first' && list1 === 'last') {
            if (inputIndexStartNum - 1 < inputValue.length - inputIndexEndNum) {
                comeBackValue = inputValue.slice(inputIndexStartNum - 1, inputValue.length - inputIndexEndNum + 1);
            } else if (inputIndexStartNum - 1 > inputValue.length - inputIndexEndNum) {
                comeBackValue = inputValue.slice(inputValue.length - inputIndexEndNum, inputIndexStartNum);
            } else {
                comeBackValue = inputValue.slice(inputValue.length - inputIndexEndNum, inputIndexStartNum);
            }
        } else if (list === 'last' && list1 === 'first') {
            if (inputIndexEndNum - 1 < inputValue.length - inputIndexStartNum) {
                comeBackValue = inputValue.slice(inputIndexEndNum - 1, inputValue.length - inputIndexStartNum + 1);
            } else if (inputIndexEndNum - 1 > inputValue.length - inputIndexStartNum) {
                comeBackValue = inputValue.slice(inputValue.length - inputIndexStartNum, inputIndexEndNum);
            } else {
                comeBackValue = inputValue.slice(inputValue.length - inputIndexStartNum, inputIndexEndNum);
            }
        } else if (list === 'last' && list1 === 'last') {
            if (inputIndexStartNum > inputIndexEndNum) {
                comeBackValue = inputValue.substring(inputValue.length - inputIndexStartNum, inputValue.length - inputIndexEndNum + 1);
            } else {
                comeBackValue = inputValue.substring(inputValue.length - inputIndexStartNum + 1, inputValue.length - inputIndexEndNum);
            }
        }
        return comeBackValue;
    }

    textsrobotsevenNew (args) {
        const [inputValue1, inputValue2, list] = [String(args.STRING1), String(args.STRING2), args.LIST1];
        let comeBackValue;
        if (list === 'first') {
            comeBackValue = inputValue2.indexOf(inputValue1) + 1;
        } else {
            comeBackValue = inputValue2.lastIndexOf(inputValue1) + 1;
        }
        return comeBackValue;
    }

    map (args) {
        const fromHigh = Cast.toNumber(args.FROMHIGH);
        const fromLow = Cast.toNumber(args.FROMLOW);
        const toHigh = Cast.toNumber(args.TOHIGH);
        const toLow = Cast.toNumber(args.TOLOW);
        const value = Cast.toNumber(args.VALUE);
        return (value - fromLow) * (toHigh - toLow) / (fromHigh - fromLow) + toLow;
    }

    constrain (args) {
        const high = Cast.toNumber(args.MAX);
        const low = Cast.toNumber(args.MIN);
        const amt = Cast.toNumber(args.VALUE);
        return ((amt) < (low) ? (low) : ((amt) > (high) ? (high) : (amt)));
    }
}

module.exports = Scratch3OperatorsBlocks;
