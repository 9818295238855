import { DeviceDataType } from "service/ext-asset-manager/device/type";
import path from 'path';
import * as fs from "../link-adapter/fs";
import * as ArduinoCli from 'service/link-adapter/arduinoCli'
import {getAppdataPath} from "../path/assetPath";
import { IS_ARDUINO_MODE } from "config/config";
import { vm } from "lib/scratch-vm";

class Burner {
    config: DeviceDataType
    temName: string
    deviceInstanceId: string
    deviceObj: any = null // extension对象(getInfo)
    constructor(config: DeviceDataType) {
        this.config = config;
        this.temName = `tmp_${config.deviceId}`;
        this.deviceInstanceId = ''; // 用于创建临时文件夹
    }
    
    async upload(port: string, file?: string) {
        if (!this.deviceObj) this.deviceObj = vm.getExtensionObj(this.config.deviceIdWithVersion);
        const runWillHook = () => {
            // 判断扩展是否有 willUpload 钩子函数
            if (this.deviceObj && this.deviceObj.getDevice) {
                const device = this.deviceObj.getDevice();
                if (device && device.willUpload && typeof device.willUpload === "function") {
                    return device.willUpload(port)
                }
            }
        }
        const runDidHook = () => {
            // 判断扩展是否有 didUpload 钩子函数
            if (this.deviceObj && this.deviceObj.getDevice) {
                const device = this.deviceObj.getDevice();
                if (device && device.didUpload && typeof device.didUpload === "function") {
                    return device.didUpload(port)
                }
            }
        }
        // 判断扩展是否有自定义的烧录函数
        if (this.deviceObj && this.deviceObj.getDevice) {
            const device = this.deviceObj.getDevice();
            // 如果有, 则不使用Arduino-cli烧录
            if (device && device.upload) {
                if (!file) file = path.join(this.getBuildPath(), `${this.temName}.ino.hex`)
                await runWillHook();
                await device.upload(port, file);
                return runDidHook()
            }
        }
        if (!(this.config && this.config.boardConfig && this.config.boardConfig.board)) return Promise.reject('烧录失败：主控config.json中未填写board')
        const args = {
            boardName: this.config.boardConfig.board,
            port: port,
            protocol: 'serial',
            fqbn: this.config.boardConfig.fqbn
        }
        if (file) {
            args['inputFile'] = file;
        } else {
            args['inputDir'] = this.getBuildPath();
        }
        await runWillHook();
        await ArduinoCli.upload(args);
        return runDidHook()
    }

    // 编译
    async compile(code) {
        if (!IS_ARDUINO_MODE) return Promise.reject('不在上传模式下， 不能编译')
        // todo： 自定义编译规则， 不依赖arduino-cli
        if (!(this.config && this.config.boardConfig && this.config.boardConfig.board)) return Promise.reject('编译失败：主控config.json中未填写board')
        // 创建临时文件
        await this.checkAndCreateTempDir();
        // 创建core目录
        await this.checkAndCreateCoreDir();
        // 创建ino
        await this.generateIno(code);

        const runWillHook = () => {
            // 判断扩展是否有 willCompile 钩子函数
            if (this.deviceObj && this.deviceObj.getDevice) {
                const device = this.deviceObj.getDevice();
                if (device && device.willCompile && typeof device.willCompile === "function") {
                    return device.willCompile(code)
                }
            }
        }
        const runDidHook = () => {
            // 判断扩展是否有 didCompile 钩子函数
            if (this.deviceObj && this.deviceObj.getDevice) {
                const device = this.deviceObj.getDevice();
                if (device && device.didCompile && typeof device.didCompile === "function") {
                    return device.didCompile(code)
                }
            }
        }

        await runWillHook();
        await ArduinoCli.compile({
            boardName: this.config.boardConfig.board,
            buildPath: this.getBuildPath(), //
            buildCachePath: this.getArduinoCorePath(),
            // libraries: string[], todo:
            inoFile: this.getInoFilePath(),
            fqbn: this.config.boardConfig.fqbn
        })
        await runDidHook();
        return this.getBuildPath();
    }

    erase(port: string) {
        if (!this.isSupportErase()) return Promise.reject("no support erase.")
        const device = this.deviceObj.getDevice();
        return device.erase(port);
    }

    // 是否支持擦除
    isSupportErase() {
        if (!IS_ARDUINO_MODE) return false;
        if (!this.deviceObj) this.deviceObj = vm.getExtensionObj(this.config.deviceIdWithVersion);
        // 判断扩展是否有自定义的烧录函数
        if (this.deviceObj && this.deviceObj.getDevice) {
            const device = this.deviceObj.getDevice();
            // 如果有, 则不使用Arduino-cli烧录
            if (device && device.erase && typeof device.erase === "function") {
                return true
            }
        }
        return false;
    }

    getBuildPath() {
        return `${this.getTempDirPath()}/build`
    }

    // 获取临时目录(每个主控对象一个，两个microbit主控是两个临时目录)
    getTempDirPath() {
        return path.join(getAppdataPath(), 'cache', this.temName);
    }

    // 获取.a目录(一类主控一个目录，两个microbit主控共用同一个.a)
    getArduinoCorePath() {
        return path.join(getAppdataPath(), 'cache/cores', this.config.deviceId);
    }

    // 获取ino文件路径
    getInoFilePath() {
        // 使用arduino-cli编译，ino文件必须和父级文件夹同名
        return path.join(this.getTempDirPath(), `${this.temName}.ino`)
    }

    // 检测临时文件夹是否存在, 如不存在则创建
    checkAndCreateTempDir() {
        // todo: 检测websocket是否连接(需要获取路径)
        const tempDirPath = this.getTempDirPath();
        return fs.access(tempDirPath)
            .catch(() => {
                // 文件夹不存在
                // 创建
                return fs.mkdir(tempDirPath);
            })
    }

    checkAndCreateCoreDir() {
        // todo: 检测websocket是否连接(需要获取路径)
        const dirPath = this.getArduinoCorePath();
        return fs.access(dirPath)
            .catch(() => {
                // 文件夹不存在
                // 创建
                return fs.mkdir(dirPath);
            })
    }

    generateIno(code) {
        return fs.writeFile(this.getInoFilePath(), code)
            .catch((e) => {
                console.error(e);
                return Promise.reject('error: can`t write to ino file\n');
            })
    }
}

export default Burner;
