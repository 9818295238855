const Blocks = require('../engine/blocks');
const xmlEscape = require('../util/xml-escape');
const log = require('../util/log');
const getMonitorIdForBlockWithArgs = require('../util/get-monitor-id');
const mutationAdapter = require('../engine/mutation-adapter');
const MonitorRecord = require('../engine/monitor-record');
class DFBlocks extends Blocks {

    // 阻止上传模式得block点击
    blocklyListen (e) {
        if (e.element === 'stackclick' && !this.runtime.deviceManager.isScratchMode()) {
            return;
        }
        super.blocklyListen(e);
    }

    blockToXML (blockId, comments) {
        const block = this._blocks[blockId];
        // block should exist, but currently some blocks' next property point
        // to a blockId for non-existent blocks. Until we track down that behavior,
        // this early exit allows the project to load.
        if (!block) return;
        // Encode properties of this block.
        const tagName = (block.shadow) ? 'shadow' : 'block';
        let xmlString =
            `<${tagName}
                id="${block.id}"
                type="${block.opcode}"
                disabled="${this.runtime.vm.extensionManager.checkDisabledByOpcode(block)}"
                ${block.topLevel ? `x="${block.x}" y="${block.y}"` : ''}
            >`;
        const commentId = block.comment;
        if (commentId) {
            if (comments) {
                if (comments.hasOwnProperty(commentId)) {
                    xmlString += comments[commentId].toXML();
                } else {
                    log.warn(`Could not find comment with id: ${commentId} in provided comment descriptions.`);
                }
            } else {
                log.warn(`Cannot serialize comment with id: ${commentId}; no comment descriptions provided.`);
            }
        }
        // Add any mutation. Must come before inputs.
        if (block.mutation) {
            xmlString += this.mutationToXML(block.mutation);
        }
        // Add any inputs on this block.
        for (const input in block.inputs) {
            if (!block.inputs.hasOwnProperty(input)) continue;
            const blockInput = block.inputs[input];
            // Only encode a value tag if the value input is occupied.
            if (blockInput.block || blockInput.shadow) {
                xmlString += `<value name="${blockInput.name}">`;
                if (blockInput.block) {
                    xmlString += this.blockToXML(blockInput.block, comments);
                }
                if (blockInput.shadow && blockInput.shadow !== blockInput.block) {
                    // Obscured shadow.
                    xmlString += this.blockToXML(blockInput.shadow, comments);
                }
                xmlString += '</value>';
            }
        }
        // Add any fields on this block.
        for (const field in block.fields) {
            if (!block.fields.hasOwnProperty(field)) continue;
            const blockField = block.fields[field];
            xmlString += `<field name="${blockField.name}"`;
            const fieldId = blockField.id;
            if (fieldId) {
                xmlString += ` id="${fieldId}"`;
            }
            const varType = blockField.variableType;
            if (typeof varType === 'string') {
                xmlString += ` variabletype="${varType}"`;
            }
            let value = blockField.value;
            if (typeof value === 'string') {
                value = xmlEscape(blockField.value);
            }
            xmlString += `>${value}</field>`;
        }
        // Add blocks connected to the next connection.
        if (block.next) {
            xmlString += `<next>${this.blockToXML(block.next, comments)}</next>`;
        }
        xmlString += `</${tagName}>`;
        return xmlString;
    }
    // 重写获取branch逻辑,支持if-elseif-else的分支机构
    getBranch (id, branchNum) {
        const block = this._blocks[id];
        if (typeof block === 'undefined') return null;
        if (!branchNum) branchNum = 1;

        let inputName = Blocks.BRANCH_INPUT_PREFIX;
        if (branchNum > 1) {
            inputName += branchNum;
        }

        // Empty C-block?
        // const input = block.inputs[inputName];
        const input = block.inputs[inputName] || block.inputs[branchNum];
        return (typeof input === 'undefined') ? null : input.block;
    }

    getInputs (block) {
        if (typeof block === 'undefined') return null;
        let inputs = this._cache.inputs[block.id];
        if (typeof inputs !== 'undefined') {
            return inputs;
        }
        inputs = {};
        for (const input in block.inputs) {
            // Ignore blocks prefixed with branch prefix.
            // 默认情况下,不会为以SUBSTACK开头的input构建缓存,此处添加也是适应if-elseif-else这个异形block的运行逻辑
            // eslint-disable-next-line max-len
            if (input.substring(0, Blocks.BRANCH_INPUT_PREFIX.length) !== Blocks.BRANCH_INPUT_PREFIX && !(/^DO\d?$|^ELSE$/g.test(input))) {
                inputs[input] = block.inputs[input];
            }
        }
        this._cache.inputs[block.id] = inputs;
        return inputs;
    }

    changeBlock (args) {
        // Validate
        if (['field', 'mutation', 'checkbox', 'speechRecord', 'sonogram', 'collapsed', 'disabled'].indexOf(args.element) === -1) return;
        let block = this._blocks[args.id];
        if (typeof block === 'undefined') block = {};
        switch (args.element) {
        case 'field':
            if (!block.fields[args.name]) return;
            if (args.name === 'VARIABLE' || args.name === 'LIST' ||
                    args.name === 'BROADCAST_OPTION') {
                const variable = this.runtime.getEditingTarget().lookupVariableById(args.value);
                if (variable) {
                    block.fields[args.name].value = variable.name;
                    block.fields[args.name].id = args.value;
                }
            } else {
                block.fields[args.name].value = args.value;
                if (block.opcode === 'sensing_of_object_menu') {
                    if (block.fields.OBJECT.value === '_stage_') {
                        this._blocks[block.parent].fields.PROPERTY.value = 'backdrop #';
                    } else {
                        this._blocks[block.parent].fields.PROPERTY.value = 'x position';
                    }
                    this.runtime.requestBlocksUpdate();
                }

                const flyoutBlock = block.shadow && block.parent ? this._blocks[block.parent] : block;
                if (flyoutBlock.isMonitored) {
                    this.runtime.requestUpdateMonitor(Map({
                        id: flyoutBlock.id,
                        params: this._getBlockParams(flyoutBlock)
                    }));
                }
            }
            break;
        case 'mutation':
            block.mutation = mutationAdapter(args.value);
            break;
        case 'checkbox': {
            if (block.fields && Object.keys(block.fields).length > 0 &&
                    block.opcode !== 'data_variable' && block.opcode !== 'data_listcontents') {
                const newId = getMonitorIdForBlockWithArgs(block.id, block.fields);
                let newBlock = this.runtime.monitorBlocks.getBlock(newId);
                if (!newBlock) {
                    newBlock = JSON.parse(JSON.stringify(block));
                    newBlock.id = newId;
                    this.runtime.monitorBlocks.createBlock(newBlock);
                }

                block = newBlock;
            }

            const wasMonitored = block.isMonitored;
            block.isMonitored = args.value;

            let isSpriteLocalVariable = false;
            if (block.opcode === 'data_variable') {
                isSpriteLocalVariable = !(this.runtime.getTargetForStage().variables[block.fields.VARIABLE.id]);
            } else if (block.opcode === 'data_listcontents') {
                isSpriteLocalVariable = !(this.runtime.getTargetForStage().variables[block.fields.LIST.id]);
            }
            const isSpriteSpecific = isSpriteLocalVariable ||
                    (this.runtime.monitorBlockInfo.hasOwnProperty(block.opcode) &&
                        this.runtime.monitorBlockInfo[block.opcode].isSpriteSpecific);
            if (isSpriteSpecific) {
                block.targetId = block.targetId || this.runtime.getEditingTarget().id;
            } else {
                block.targetId = null;
            }

            if (wasMonitored && !block.isMonitored) {
                this.runtime.requestHideMonitor(block.id);
            } else if (!wasMonitored && block.isMonitored) {
                if (!this.runtime.requestShowMonitor(block.id)) {
                    this.runtime.requestAddMonitor(MonitorRecord({
                        id: block.id,
                        targetId: block.targetId,
                        spriteName: block.targetId ? this.runtime.getTargetById(block.targetId).getName() : null,
                        opcode: block.opcode,
                        params: this._getBlockParams(block),
                        value: '',
                        mode: block.opcode === 'data_listcontents' ? 'list' : 'default'
                    }));
                }
            }
            break;
        }
        case 'speechRecord': {
            if (args.value) {
                this.runtime.requestAddMonitor(MonitorRecord({
                    id: args.id,
                    targetId: block.targetId,
                    spriteName: block.targetId ? this.runtime.getTargetById(block.targetId).getName() : null,
                    opcode: block.opcode,
                    params: this._getBlockParams(block),
                    value: '',
                    mode: 'speechRecord'
                }));
            } else {
                this.runtime.requestHideMonitor(args.id);
            }

            break;
        }
        case 'sonogram': {
            if (args.value) {
                this.runtime.requestAddMonitor(MonitorRecord({
                    id: args.id,
                    targetId: block.targetId,
                    spriteName: block.targetId ? this.runtime.getTargetById(block.targetId).getName() : null,
                    opcode: block.opcode,
                    params: this._getBlockParams(block),
                    value: '',
                    mode: 'sonogram'
                }));
            } else {
                this.runtime.requestHideMonitor(args.id);
            }
            break;
        }
        default: {
            block[args.element] = args.value;
            break;
        }
        }

        this.emitProjectChanged();

        this.resetCache();
    }
}

module.exports = DFBlocks;
