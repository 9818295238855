import * as events from "events";
import SerialportWs, {
  SerialOpenOptions,
  SetOptions,
} from "./websocket/serialportWs";
import UdpWs from "./websocket/udpWs";

export type ErrorCallback = (err: Error | null) => void;
type UdpType = "udp4" | "udp6";

export default class UDP extends events.EventEmitter {
  private udpWs: UdpWs;
  wsOpened: boolean = false;
  constructor() {
    super();
    this.udpWs = new UdpWs();
    this.udpWs.on("wsOpen", this._onWsOpen.bind(this));
    this.udpWs.on("open", () => this.emit("open"));
    this.udpWs.on("error", (...args) => this.emit("error", ...args));
    this.udpWs.on("data", (...args) => this.emit("data", ...args));
    this.udpWs.on("close", (...args) => this.emit("close", ...args));
    this.udpWs.on("message", (args) => {
      const decoder = new TextDecoder("utf-8");
      const msgInfo = {
        ...args,
        message: Buffer.from(decoder.decode(args.message),'base64').toString(),
      };
      this.emit("message", msgInfo);
    });
  }

  get isOpen() {
    return this.udpWs.isOpen() || false;
  }

  _onWsOpen() {
    console.log("udp ws opened");
    this.wsOpened = true;
    this.emit("wsOpened");
  }

  waitCreated() {
    if (this.wsOpened) {
      return Promise.resolve();
    } else {
      return new Promise<void>((resolve, reject) => {
        // todo: 超时?
        this.once("wsOpened", () => {
          resolve();
        });
      });
    }
  }

  createSocket(type: UdpType) {
    this.waitCreated().then(() => {
      this.udpWs.createSocket(type);
    });
  }

  bindPort({ port, ip }: any, callBack?: () => void) {
    this.waitCreated().then(() => {
      this.udpWs.bindPort(port, ip).then(() => {
        if (callBack) callBack();
      });
    });
  }

  sendUdpMessage(options: any) {
    const msg = Buffer.from(options.message).toString("base64");
    const param = {
      ...options,
      message: msg,
      length: msg.length,
    }
    this.waitCreated().then(() => {
      this.udpWs.sendUdpMessage(param);
    });
  }

  setBroadcast(broadcast: boolean) {
    this.waitCreated().then(() => {
      this.udpWs.setBroadcast(broadcast);
    });
  }

  closeUdp(callBack?: () => void) {
    this.waitCreated().then(() => {
      this.udpWs.closeUdp().then(() => {
        callBack && callBack();
      });
    });
  }
}
