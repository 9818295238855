import { CloseOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useMemo, useRef, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import styles from './DFModal.module.scss';
import DFButton from "../df-button/DFButton";
import { CloseIcon } from "component/icon/CloseIcon"

// 统一的弹窗样式, 不需要过于复杂
interface DFModalProps {
    isShow: boolean;
    onClose: () => void;
    // centered?: boolean;
    // cancelText?: string;
    // okText?: string;
    icon?: React.ReactNode
    title?: string | null;
    width?: string; // 700px 70%vh ...
    height?: string;
    // maskClosable?: boolean
    // closable?: boolean
    // keyboard?: boolean;
    // onCancel?: () => void;
    // onOk?: () => void;
    children?: React.ReactNode;
    // footer?: React.ReactNode;
    // header?: React.ReactNode;
    // closeIcon?: React.ReactNode;
    // contentStyle?: CSSProperties;
}

const DFModal: React.FC<DFModalProps> = ({
    isShow,
    onClose,
    icon,
    // centered = false,
    // closeIcon,
    // cancelText = '取消',
    // okText = '确定',
    title,
    children,
    // footer,
    // // header,
    // keyboard = true,
    // onCancel,
    // onOk,
    width,
    height,
    // maskClosable = false,
    // closable = true,
    // contentStyle,
}) => {
    const [modalVisible, setModalVisible] = useState(isShow);
    const modalContentRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        setModalVisible(isShow);
    }, [isShow]);

    const closeModal = () => {
        setModalVisible(false);
        onClose();
    };

    // 点击遮罩, 关闭弹窗
    const handleMaskClick = (event) => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
            setModalVisible(false);
            onClose()
        }
    }

    // ESC 关闭弹窗
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeModal();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const modalContent = (
        <div className={`${styles.modal} `} onClick={handleMaskClick}>
            <div 
                className={`${styles.modalWrapper} ${styles.centered}`} 
                onClick={e=>e.stopPropagation()}
                style={{ width: width || "416px", height: height || "480px" }} 
                ref={modalContentRef}
            >
                <div className={styles.header}>
                    <div className={styles.left}>
                        {icon&&<div className={styles.icon}>{icon}</div>}
                        <div className={styles.title}>{title}</div>
                    </div>
                    <div className={styles.closeButton}  onClick={closeModal}>
                        <CloseIcon />
                    </div>
                </div>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );

    return modalVisible
        ? ReactDOM.createPortal(modalContent, document.body)
        : null;
};

export default DFModal;
