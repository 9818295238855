import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../store";


const initialState: string[] = []

export const extensionDownloadingSlice = createSlice({
    name: 'extensionDownloading',
    initialState,
    reducers: {
        addDownloadingExtension: (state, action: PayloadAction<string>) => {
            state.push(action.payload)
        },
        removeDownloadingExtension: (state, action: PayloadAction<string>) => {
            const index = state.indexOf(action.payload)
            if (index > -1) {
                state.splice(index, 1)
            }
        },
    },
})

export const {
    addDownloadingExtension,
    removeDownloadingExtension,
} = extensionDownloadingSlice.actions

export const selectDownloadingExtensions = (state: RootState) => state.downloadingExtensions

export default extensionDownloadingSlice.reducer
