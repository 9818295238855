import ScratchMonitorList from './monitor-list'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { moveMonitorRect } from "redux/monitor-layout/monitorLayoutSlice";
import { vm } from "../../lib/scratch-vm";
import { OrderedMap } from "immutable";
import throttle from "lodash.throttle";
import { selectMode } from "../../redux/mode/modeSlice";
import { getStageDimensions } from "lib/screen-utils";
import { selectStageSize } from "../../redux/stage-size/stageSizeSlice";
import { useSpeechRecognition } from './util/useSpeechRecognition';

export const MonitorList = () => {
  const [monitors, setMonitors] = useState(() => vm.runtime.getMonitorState());
  const dispatch = useDispatch();
  const { isPlayerOnly, isFullScreen } = useSelector(selectMode)
  const { stageSize } = useSelector(selectStageSize)
  const { levels } = useSpeechRecognition()

  useEffect(() => {
    const onMonitorsUpdate = throttle((monitorList) => {
      setMonitors(monitorList);
    }, 30)
    vm.on('MONITORS_UPDATE', onMonitorsUpdate);
    return () => {
      vm.removeListener('MONITORS_UPDATE', onMonitorsUpdate);
    }
  }, [])
  const moveMonitorRect_ = (id, x, y) => dispatch(moveMonitorRect({ monitorId: id, newX: x, newY: y }))
  const stageDimensions = getStageDimensions(stageSize, isFullScreen);

  return <ScratchMonitorList
    monitors={monitors}
    moveMonitorRect={moveMonitorRect_}
    draggable={!(isFullScreen || isPlayerOnly)}
    stageSize={stageDimensions}
    levels={levels}
  />
}
