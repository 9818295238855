import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

// 舞台全屏模式是在 modeSlice 中控制
interface CodeEditorSizeState {
    size: 'small'|'large'|'medium'
}

const initialState: CodeEditorSizeState = {
    size: 'medium'
}

export const codeEditorSizeSlice = createSlice({
    name: 'codeEditorSize',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setMediumCodeEditor: (state) => {
            state.size = 'medium';
        },
        setSmallCodeEditor: (state) => {
            state.size = 'small';
        },
        setLargeCodeEditor: (state) => {
            state.size = 'large';
        },
        setCodeEditorSize: (state,action: PayloadAction<CodeEditorSizeState>) => {
            state.size = action.payload.size
        }
    },
})

export const {setMediumCodeEditor, setSmallCodeEditor,setLargeCodeEditor,setCodeEditorSize} = codeEditorSizeSlice.actions

export const selectCodeEditorSize= (state: RootState) => state.codeEditorSize

export default codeEditorSizeSlice.reducer
