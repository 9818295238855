import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';

import Box from 'component/box/box.jsx';
import {Modal} from '../../modal';

import ScanningStep from './scanning-step';
import AutoScanningStep from './auto-scanning-step';
import ConnectingStepComponent from './connecting-step-component.jsx';
import ConnectedStepComponent from './connected-step-component.jsx';
import ErrorStepComponent from './error-step-component.jsx';
import UnavailableStepComponent from './unavailable-step-component.jsx';
import UpdatePeripheralStepComponent from './update-peripheral-step-component.jsx';

import styles from './connection-modal-component.module.scss';

const PHASES = keyMirror({
    scanning: null,
    connecting: null,
    connected: null,
    error: null,
    unavailable: null,
    updatePeripheral: null
});

const ConnectionModalComponent = props => (
    <Modal
        className={styles.modalContent}
        contentLabel={props.name}
        headerClassName={styles.header}
        headerImage={props.connectionSmallIconURL}
        id="connectionModal"
        onHelp={props.onHelp}
        onRequestClose={props.onCancel}
    >
        <Box className={styles.body}>
            {props.phase === PHASES.scanning && !props.useAutoScan && <ScanningStep {...props} />}
            {props.phase === PHASES.scanning && props.useAutoScan && <AutoScanningStep {...props} />}
            {props.phase === PHASES.connecting && <ConnectingStepComponent {...props} />}
            {props.phase === PHASES.connected && <ConnectedStepComponent {...props} />}
            {props.phase === PHASES.error && <ErrorStepComponent {...props} />}
            {props.phase === PHASES.unavailable && <UnavailableStepComponent {...props} />}
            {props.phase === PHASES.updatePeripheral && <UpdatePeripheralStepComponent {...props} />}
        </Box>
    </Modal>
);

ConnectionModalComponent.propTypes = {
    connectingMessage: PropTypes.node.isRequired,
    connectionSmallIconURL: PropTypes.string,
    connectionTipIconURL: PropTypes.string,
    name: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
    onHelp: PropTypes.func.isRequired,
    phase: PropTypes.oneOf(Object.keys(PHASES)).isRequired,
    title: PropTypes.string.isRequired,
    useAutoScan: PropTypes.bool.isRequired
};

ConnectionModalComponent.defaultProps = {
    connectingMessage: 'Connecting'
};

export {
    ConnectionModalComponent as default,
    PHASES
};
