import fs from 'service/link-adapter/fs';
import {getBuiltinExtJsonPath, getLocalExtJsonPath} from "../../path/assetPath";
import {getExtensionID, getExtensionIDWithVersion} from "./util";
import BuiltinScratchExtensionData from 'lib/extension-lib';
import { ExtensionConfig, BuiltinScratchExtensionDataType, ExternalExtensionDataType} from "./type";
import {DeviceDataType} from "../device/type";
import {getDeviceID, getDeviceIDWithVersion} from "../device/util";
import { ExtensionMaps } from '../extAssetManager';
import { compareVersion } from 'util/compareVersion';
import { EXTENSION_ASSET_SERVER, IS_SCRATCH_MODE, IS_WEB_PLATFORM, MINDPLUS_MODE } from 'config/config';
// 获取内置Scratch原生extension列表
const getBuiltinScratchExtensionData = async (): Promise<BuiltinScratchExtensionDataType[]> => {
  // todo: 主控中的扩展也会内置
  return BuiltinScratchExtensionData.map((item: any) => ({
    ...item,
    id: item.extensionId,
    isBuiltinScratch: true,
    isSupportStage: true,
    isDownloaded: true,
    isDevice: false,
    version: item.version || '0.0.1'
  }));
}
// 获取离线版内置的extension列表
const getBuiltinExtensionData = (): Promise<ExternalExtensionDataType[]> => {
    // todo: 在线版兼容(获取文件的方式改为fetch)
    return fs.readFile(getBuiltinExtJsonPath()).then((data: string | ArrayBuffer) => {
        // 将字符串解析成对象
        let extensionData: ExtensionConfig[] = [];
        try {
            extensionData = JSON.parse(data.toString());
        } catch {
        }
        // 解析数据
        const extensionInnerData: ExternalExtensionDataType[] = extensionData.map(item => {
            // 1.生成唯一的id,  "author-id"
            const _item: ExternalExtensionDataType = {
                ...item,
                extensionId: getExtensionID(item),
                extensionIdWithVersion: getExtensionIDWithVersion(item),
                hasUpdate: false,
                isDownloaded: true,
                isBuiltinScratch: false,
                isBuiltin: true // 标为内置
            }
            // 2.cover 转换成绝对路径
            // _item.cover = getCoverAbsolutePath(_item);
            // // 3.main 转换成绝对路径
            // _item.main = getMainAbsolutePath(_item);
            return _item;
        })
        return extensionInnerData
    })
}

// 获取本地的extension列表
const getLocalExtensionData = (): Promise<ExternalExtensionDataType[]> => {
    // todo: 在线版兼容(在线版没有local)
    return fs.readFile(getLocalExtJsonPath()).then((data: string | ArrayBuffer) => {
        // 将字符串解析成对象
        let extensionData: ExtensionConfig[] = [];
        try {
            extensionData = JSON.parse(data.toString());
        } catch {
        }
        // 解析数据
        const extensionInnerData: ExternalExtensionDataType[] = extensionData.map(item => {
            // 1.生成唯一的id,  "author-id"
            const _item: ExternalExtensionDataType = {
                ...item,
                extensionId: getExtensionID(item),
                extensionIdWithVersion: getExtensionIDWithVersion(item),
                isBuiltin: false,
                hasUpdate: false,
                isDownloaded: true,
                isBuiltinScratch: false
            }
            // 2.cover 转换成绝对路径
            // _item.cover = getCoverAbsolutePath(_item);
            // // 3.main 转换成绝对路径
            // _item.main = getMainAbsolutePath(_item);
            return _item;
        })
        return extensionInnerData
    })
}


// 获取服务器的extension列表
export const getServerExtensionData = async (): Promise<ExternalExtensionDataType[]> => {
    let extensionData: ExternalExtensionDataType[] = [];
    try {
        const response = await fetch(`${EXTENSION_ASSET_SERVER}/${MINDPLUS_MODE}/extensions/ext.json`, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache', // 防止缓存
                'Pragma': 'no-cache'         // 兼容旧版HTTP
            }
        })
        extensionData = await response.json()
        // 解析数据
        return extensionData.map(item => {
            // 1.生成唯一的id,  "author-id"
            const _item: ExternalExtensionDataType = {
                ...item,
                extensionId: getExtensionID(item),
                extensionIdWithVersion: getExtensionIDWithVersion(item),
                isBuiltin: false,
                isDownloaded: false,
                isBuiltinScratch: false
            }
            return _item;
        })
    } catch (e) {
        console.log("e=====", e)
        return extensionData
    }
    // todo:
}

// 获取扩展数据
const getExtensionData = (callback?: (data: ExtensionMaps) => void): Promise<ExtensionMaps> => {
    // 1.获取内置scratch
    const promise1 = getBuiltinScratchExtensionData();

    // 2.获取内置(安装目录/extensions) scratch在线版的extension只从服务器获取
    const promise2 = (IS_WEB_PLATFORM && IS_SCRATCH_MODE)? Promise.resolve([]) : getBuiltinExtensionData();

    // 3.获取本地
    const promise3 = (IS_WEB_PLATFORM && IS_SCRATCH_MODE)? Promise.resolve([]) : getLocalExtensionData();

    // 4.获取服务器
    const promise4 = getServerExtensionData();

    // 5.每次数据获取成功都调用一次回调
    let extensionData: [ExternalExtensionDataType[], ExternalExtensionDataType[], ExternalExtensionDataType[], ExternalExtensionDataType[]] = [[], [], [], []];
    const parseData = () => {
        let extensionDataMap: ExtensionMaps = {};
        // 合并数据
        const builtinScratchData = extensionData[0];
        const builtinData = extensionData[1];
        const localData = extensionData[2];
        const serverData = extensionData[3];

        builtinScratchData.forEach(item => {
            if (!extensionDataMap[item.extensionId]) {
                extensionDataMap[item.extensionId] = {}
            }
            extensionDataMap[item.extensionId][item.version] = item;
        });

        builtinData.forEach(item => {
            if (!extensionDataMap[item.extensionId]) {
                extensionDataMap[item.extensionId] = {}
            }
            extensionDataMap[item.extensionId][item.version] = item;
        })

        localData.forEach(item => {
            if (!extensionDataMap[item.extensionId]) {
                extensionDataMap[item.extensionId] = {}
            }
            extensionDataMap[item.extensionId][item.version] = item;
        })

        serverData.forEach(item => {
            if (!extensionDataMap[item.extensionId]) {
                extensionDataMap[item.extensionId] = {}
            }
            // 该版本, 本地已下载
            if (extensionDataMap[item.extensionId][item.version]) return;
            extensionDataMap[item.extensionId][item.version] = {
                ...item,
                isBuiltin: false,
                isDownloaded: false,
            };
        })

        // hasUpdate
        Object.values(extensionDataMap).forEach(item => {
            const versions = Object.keys(item);
            versions.forEach((version) => {
                // 比较版本号
                const ret = versions.some(v => compareVersion(v, version) > 0)
                // 有版本号更高的
                if (ret) {
                    item[version].hasUpdate = true;
                }
            })
        })

        return extensionDataMap;
    }
    const promiseList = [promise1, promise2, promise3, promise4].map((promise, index) => {
        return promise.then(data => {
            // 缓存数据
            extensionData[index] = data;
            const data_ = parseData();
            // 刷新数据
            if (callback) callback(data_)
            return data_;
        }).catch(e => console.error('获取扩展列表失败', index, e))
    })
    return Promise.all(promiseList).then(() => parseData())
}

export {
    getExtensionData, // 获取extension列表
}
