

// 有些初始化内容必须放在组件渲染之前
import {useVmListener} from "./useVmListener";
import {useVmManager} from "./useVmManager";
// import {useProjectLoad} from "./useProjectLoad";
// import {useProjectFetch} from "./useProjectFetch";
import {useStage} from "./useStage";
import { useFontLoad } from "./useFontLoad";
import { usePageLeaveConfirm } from "./usePageLeaveConfirm";
import { useProject } from "service/project/useProject";

export const useInit = () => {
    // todo: 初始化vmListener(监听事件的挂载)
    const {isVmListenerInit} = useVmListener({})

    // todo: 初始化云变量服务

    // todo: 初始化本地sb3加载

    // todo: 初始化vmManager(vm资源初始化, 启动, 项目加载(有数据, 用vm加载))
    useVmManager();

    // 初始化项目加载
    useProject()


    // todo: 初始化云端项目的保存

    // todo: 初始化窗口title的修改逻辑

    // todo: 初始化云端项目数据的读取逻辑(包含默认项目)
    // useProjectFetch()

    // todo: query参数的解析(视频教程卡片是用query参数定位的)

    // todo: 初始化字体
    useFontLoad()

    // todo: 初始化崩溃页面

    // todo: 初始化翻译
    const { isStageInit } = useStage(); 
  
    //项目改变后,离开页面前提示
    usePageLeaveConfirm()

    return {
        isInit: isVmListenerInit && isStageInit
    }
}
