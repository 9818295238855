import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {setVariableValue} from 'lib/variable-utils';

import SliderMonitorComponent from './slider-monitor-component';
import {vm} from "lib/scratch-vm";

class SliderMonitor extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSliderUpdate'
        ]);

        this.state = {
            value: props.value
        };
    }
    componentWillReceiveProps (nextProps) {
        if (this.state.value !== nextProps.value) {
            this.setState({value: nextProps.value});
        }
    }
    handleSliderUpdate (e) {
        this.setState({value: Number(e.target.value)});
        const {targetId, id: variableId} = this.props;
        setVariableValue(vm, targetId, variableId, Number(e.target.value));
    }
    render () {
        const {
            value, // eslint-disable-line no-unused-vars
            ...props
        } = this.props;
        return (
            <SliderMonitorComponent
                {...props}
                value={this.state.value}
                onSliderUpdate={this.handleSliderUpdate}
            />
        );
    }
}

SliderMonitor.propTypes = {
    id: PropTypes.string,
    targetId: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
};

export default SliderMonitor
