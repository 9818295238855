import React, { FC, PropsWithChildren } from 'react';
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { selectIntl } from "redux/intl/intlSlice";

const CustomIntlProvider: FC<PropsWithChildren> = ({ children }) => {
  const { locale, messages } = useSelector(selectIntl)

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default CustomIntlProvider;
