import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store";

// Define a type for the slice state
interface ModeState {
    showBranding: boolean,
    isFullScreen: boolean,
    isPlayerOnly: boolean,
    hasEverEnteredEditor: boolean
}

// Define the initial state using that type
const initialState: ModeState = {
    showBranding: false,
    isFullScreen: false,
    isPlayerOnly: false,
    hasEverEnteredEditor: true
}

export const modeSlice = createSlice({
    name: 'mode',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setFullScreen: (state, action: PayloadAction<boolean>) => {
            state.isFullScreen = action.payload;
        },
        setPlayer: (state, action: PayloadAction<boolean>) => {
            state.isPlayerOnly = action.payload;
            state.hasEverEnteredEditor = state.hasEverEnteredEditor || !state.isPlayerOnly;
        }
    },
})

export const {setFullScreen, setPlayer} = modeSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectMode = (state: RootState) => state.mode

export default modeSlice.reducer
