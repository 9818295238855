import { useCallback, useEffect, useMemo, useState } from "react"
export const COUNT_PER_PAGE = 3 * 8;

export const usePagination = (dataSource: any, libType) => {
    const [currentPage, setCurrentPage] = useState(1)
    const pagedItems = useMemo(() => {
        // 这一页的数据
        const offset = (currentPage - 1) * COUNT_PER_PAGE;
        return dataSource.slice(offset, offset + COUNT_PER_PAGE)
    }, [currentPage, dataSource])

    const handlePageNumberChange = useCallback((num: number) => {
        setCurrentPage(num)
    }, [])

    // 切换后翻到第一页
    useEffect(() => {
        setCurrentPage(1)
    }, [libType])

    return {
        currentPage,
        handlePageNumberChange,
        pagedItems
    }
}
