
// 比较版本, 返回值:  1 version1>version2, 0 =, -1 <
export const compareVersion = (version1: string, version2: string): 0|1|-1 => {
    let ret = version1.split('.');
    let ver1 = {
        major: parseInt(ret[0]), // 主版本号
        minor: parseInt(ret[1]), // 次版本号
        patch: parseInt(ret[2]), // 补丁版本号
    }
    ret = version2.split('.');
    let ver2 = {
        major: parseInt(ret[0]), // 主版本号
        minor: parseInt(ret[1]), // 次版本号
        patch: parseInt(ret[2]), // 补丁版本号
    }
    if (ver1.major > ver2.major) return 1;
    if (ver1.major < ver2.major) return -1;
    if (ver1.minor === undefined || ver2.minor === undefined) return 0;
    if (ver1.minor > ver2.minor) return 1;
    if (ver1.minor < ver2.minor) return -1;
    if (ver1.patch === undefined || ver2.patch === undefined) return 0;
    if (ver1.patch > ver2.patch) return 1;
    if (ver1.patch < ver2.patch) return -1;
    return 0;
}
// 测试结果
// console.log(compareVersion('0.0.2', '0.0.2')) // 0
// console.log(compareVersion('0.0.2', '0.0.1')) // 1
// console.log(compareVersion('0.0.2', '0.0.3')) // -1
// console.log(compareVersion('0.2', '0.2')) // 0
// console.log(compareVersion('0.2', '0.1')) // 1
// console.log(compareVersion('0.2', '0.3')) // -1
// console.log(compareVersion('0.2.1', '0.2')) // 0
// console.log(compareVersion('0.2', '0.2.1')) // 0
// console.log(compareVersion('2', '2')) // 0
// console.log(compareVersion('2', '1')) // 1
// console.log(compareVersion('2', '3')) // -1
// console.log(compareVersion('2', '2.1')) // 0
// console.log(compareVersion('2', '2.1.1')) // 0

/**
 * version符合fuzzyVersion范围, 返回true
 * @param {*} fuzzyVersion: 模糊版本号, 有通配符
 * @param {*} version: 准确的版本号, 0.0.1
 *  0.0.1 匹配指定版本
 *  ^1.2.1 匹配 >=1.2.1 且 <2.0.0的版本
 *  ~0.1.1 匹配 >=0.1.1 且 <0.2.0
 *  *      匹配所有版本
 *  < <= > >= = 多个表达式之间用 空格 分隔表示并集，用 || 分隔交集
 */
export const checkFuzzyVersion = (fuzzyVersion: string, version: string): boolean => {
    if (fuzzyVersion === '*') return true;
    if (fuzzyVersion[0] === '^') {
        // 例: ^1.2.3
        const fuzzyVersion_ = fuzzyVersion.slice(1);
        // 首先要 >=1.2.3
        if (compareVersion(version, fuzzyVersion_) === -1) return false;
        // 其次 <2.0.0 
        let major = parseInt(fuzzyVersion_.split('.')[0]);
        return compareVersion(version, `${major+1}`) === -1;
    }
    if (fuzzyVersion[0] === '~') {
        // 例: ^1.2.3
        const fuzzyVersion_ = fuzzyVersion.slice(1);
        // 首先要 >=1.2.3
        if (compareVersion(version, fuzzyVersion_) === -1) return false;
        // 其次 <1.3.0 
        let ret = fuzzyVersion.slice(1).split('.');
        let major = ret[0];
        let minor = parseInt(ret[1])||0;
        return compareVersion(version, `${major}.${minor+1}`) === -1;
    }
    // console.log(fuzzyVersion, version, /^(\d+\.){0,2}\d+$/.test(fuzzyVersion),  compareVersion(version, fuzzyVersion))
    // 指定版本号
    if (/^(\d+\.){0,2}\d+$/.test(fuzzyVersion)) return compareVersion(version, fuzzyVersion) === 0;
    // < <= > >=
    const union = fuzzyVersion.split('||'); // 先分割并集
    for (let item of union) {
        const inter = item.split(' ');
        let ret = false;
        // 交集优先判断
        for (let i = 0; i < inter.length; i++) {
            let item1 = inter[i];
            if (item1[0] === '>'&&item1[1] === '=') {
                let fuzzyVersion_ = item1.slice(2);
                // version >= fuzzyVersion, 结果为false, 跳出交集判断
                if (compareVersion(version, fuzzyVersion_) === -1) break;
            } else if (item1[0] === '<'&&item1[1] === '=') {
                let fuzzyVersion_ = item1.slice(2);
                // version <= fuzzyVersion
                if (compareVersion(version, fuzzyVersion_) === 1) break;
            } else if (item1[0] === '>') {
                let fuzzyVersion_ = item1.slice(1);
                // version > fuzzyVersion
                if (compareVersion(version, fuzzyVersion_) !== 1) break;
            } else if (item1[0] === '<') {
                let fuzzyVersion_ = item1.slice(1);
                // version < fuzzyVersion
                if (compareVersion(version, fuzzyVersion_) !== -1) break;
            } else if (item1[0] === '=') {
                let fuzzyVersion_ = item1.slice(1);
                // version < fuzzyVersion
                if (compareVersion(version, fuzzyVersion_) !== 0) break;
            }
            if (i === inter.length - 1) ret = true;
        }
        if (ret === true) return true;
    }
    return false;
}
// 测试结果
// console.log(checkFuzzyVersion("*", "1.2.3")) // true
// console.log(checkFuzzyVersion("*", "1.2")) // true
// console.log(checkFuzzyVersion("1.2.3", "1.2.3")) // true
// console.log(checkFuzzyVersion("1.2.3", "1.2.4")) // false
// console.log(checkFuzzyVersion("1.2.3", "1.2.2")) // false
// console.log(checkFuzzyVersion("1.2", "1.2.3")) // true
// console.log(checkFuzzyVersion("1.2", "1.3.3")) // false
// console.log(checkFuzzyVersion("1.2", "1.1.3")) // false
// console.log(checkFuzzyVersion("1", "1.2.3")) // true
// console.log(checkFuzzyVersion("1", "0.2.3")) // false
// console.log(checkFuzzyVersion("1", "2.2.3")) // false
// console.log(checkFuzzyVersion("^1.2.3", "1.2.3")) // true
// console.log(checkFuzzyVersion("^1.2.3", "1.2.16")) // true
// console.log(checkFuzzyVersion("^1.2.3", "1.2.0")) // false
// console.log(checkFuzzyVersion("^1.2.3", "1.3.0")) // true
// console.log(checkFuzzyVersion("^1.2.3", "1.1.0")) // false
// console.log(checkFuzzyVersion("^1.2.3", "0.1.0")) // false
// console.log(checkFuzzyVersion("^1.2.3", "2.1.0")) // false
// console.log(checkFuzzyVersion("~1.2.3", "1.2.3")) // true
// console.log(checkFuzzyVersion("~1.2.3", "1.2.16")) // true
// console.log(checkFuzzyVersion("~1.2.3", "1.2.0")) // false
// console.log(checkFuzzyVersion("~1.2.3", "1.3.0")) // false
// console.log(checkFuzzyVersion("~1.2.3", "1.1.0")) // false
// console.log(checkFuzzyVersion("~1.2.3", "0.1.0")) // false
// console.log(checkFuzzyVersion("~1.2.3", "2.1.0")) // false
// console.log(checkFuzzyVersion(">=1.2.3", "1.2.3")) // true
// console.log(checkFuzzyVersion(">=1.2.3", "1.2.2")) // false
// console.log(checkFuzzyVersion(">=1.2.3", "1.2.4")) // true
// console.log(checkFuzzyVersion(">=1.2.3", "1.1.0")) // false
// console.log(checkFuzzyVersion(">1.2.3", "1.2.3")) // false
// console.log(checkFuzzyVersion(">1.2.3", "1.2.2")) // false
// console.log(checkFuzzyVersion(">1.2.3", "1.2.4")) // true
// console.log(checkFuzzyVersion(">1.2.3", "1.1.0")) // false
// console.log(checkFuzzyVersion("<=1.2.3", "1.2.3")) // true
// console.log(checkFuzzyVersion("<=1.2.3", "1.2.2")) // true
// console.log(checkFuzzyVersion("<=1.2.3", "1.2.4")) // false
// console.log(checkFuzzyVersion("<=1.2.3", "1.1.0")) // true
// console.log(checkFuzzyVersion("<1.2.3", "1.2.3")) // false
// console.log(checkFuzzyVersion("<1.2.3", "1.2.2")) // true
// console.log(checkFuzzyVersion("<1.2.3", "1.2.4")) // false
// console.log(checkFuzzyVersion("<1.2.3", "1.1.0")) // true
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.5", "1.2.3")) // true
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.5", "1.2.2")) // false
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.5", "1.2.4")) // true
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.5", "1.1.0")) // false
// console.log(checkFuzzyVersion(">1.2.3 <1.2.5", "1.2.3")) // false
// console.log(checkFuzzyVersion(">1.2.3 <1.2.5", "1.2.4")) // true
// console.log(checkFuzzyVersion("<1.2.5 >1.2.3", "1.2.4")) // true
// console.log(checkFuzzyVersion("<=1.2.5 >1.2.3", "1.2.6")) // false
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||>2.0.0 <2.2", "1.2.6")) // true
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||>2.0.0 <2.2", "1.2.10")) // false
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||>2.0.0 <2.2", "1.4.10")) // true
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||>2.0.0 <2.2", "1.5.10")) // false
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||>2.0.0 <2.2", "1.9.10")) // false
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||>2.0.0 <2.2", "2.1.10")) // true
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||>2.0.0 <2.2", "2.2.10")) // false
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||>2.0.0 <=2.2", "2.2.10")) // true
// console.log(checkFuzzyVersion(">=1.2.3 <1.2.9||>1.4.0 <1.5.0||=2.2.2", "2.2.2")) // true