import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";

import Box from "component/box/box.jsx";
import ActionMenu from "component/action-menu/action-menu.jsx";
import styles from "./stage-selector.module.scss";
import { isRtl } from "scratch-l10n";

import backdropIcon from "component/action-menu/icon--backdrop.svg";
import fileUploadIcon from "component/action-menu/icon--file-upload.svg";
import paintIcon from "component/action-menu/icon--paint.svg";
import surpriseIcon from "component/action-menu/icon--surprise.svg";
import searchIcon from "component/action-menu/icon--search.svg";

const messages = defineMessages({
  addBackdropFromLibrary: {
    id: "gui.stage.addBackdropFromLibrary",
    description: "Button to add a stage in the target pane from library",
    defaultMessage: "Choose a Backdrop",
  },
  addBackdropFromPaint: {
    id: "gui.stageSelector.addBackdropFromPaint",
    description: "Button to add a stage in the target pane from paint",
    defaultMessage: "Paint",
  },
  addBackdropFromSurprise: {
    id: "gui.stageSelector.addBackdropFromSurprise",
    description: "Button to add a random stage in the target pane",
    defaultMessage: "Surprise",
  },
  addBackdropFromFile: {
    id: "gui.stageSelector.addBackdropFromFile",
    description: "Button to add a stage in the target pane from file",
    defaultMessage: "Upload Backdrop",
  },
});

const StageSelector = (props) => {
  const {
    backdropCount,
    containerRef,
    dragOver,
    fileInputRef,
    intl,
    selected,
    raised,
    receivedBlocks,
    url,
    onBackdropFileUploadClick,
    onBackdropFileUpload,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onNewBackdropClick,
    onSurpriseBackdropClick,
    onEmptyBackdropClick,
    ...componentProps
  } = props;
  return (
    <Box
      className={classNames(styles.stageSelector, {
        [styles.isSelected]: selected,
        [styles.raised]: raised || dragOver,
        [styles.receivedBlocks]: receivedBlocks,
      })}
      componentRef={containerRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...componentProps}
    >
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <FormattedMessage
            defaultMessage="Stage"
            description="Label for the stage in the stage selector"
            id="gui.stageSelector.stage"
          />
        </div>
      </div>
      {url ? <img className={styles.costumeCanvas} src={url} /> : null}
      <div className={styles.label}>
        <FormattedMessage
          defaultMessage="Backdrops"
          description="Label for the backdrops in the stage selector"
          id="gui.stageSelector.backdrops"
        />
      </div>
      <div className={styles.count}>{backdropCount}</div>
      <ActionMenu
        className={styles.addButton}
        img={backdropIcon}
        moreButtons={[
          {
            title: intl.formatMessage(messages.addBackdropFromFile),
            img: fileUploadIcon,
            onClick: onBackdropFileUploadClick,
            fileAccept: ".svg, .png, .bmp, .jpg, .jpeg, .gif",
            fileChange: onBackdropFileUpload,
            fileInput: fileInputRef,
            fileMultiple: true,
          },
          {
            title: intl.formatMessage(messages.addBackdropFromSurprise),
            img: surpriseIcon,
            onClick: onSurpriseBackdropClick,
          },
          {
            title: intl.formatMessage(messages.addBackdropFromPaint),
            img: paintIcon,
            onClick: onEmptyBackdropClick,
          },
          {
            title: intl.formatMessage(messages.addBackdropFromLibrary),
            img: searchIcon,
            onClick: onNewBackdropClick,
          },
        ]}
        title={intl.formatMessage(messages.addBackdropFromLibrary)}
        tooltipPlace={isRtl(intl.locale) ? "right" : "left"}
        onClick={onNewBackdropClick}
      />
    </Box>
  );
};

StageSelector.propTypes = {
  backdropCount: PropTypes.number.isRequired,
  containerRef: PropTypes.func,
  dragOver: PropTypes.bool,
  fileInputRef: PropTypes.func,
  onBackdropFileUpload: PropTypes.func,
  onBackdropFileUploadClick: PropTypes.func,
  onClick: PropTypes.func,
  onEmptyBackdropClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onNewBackdropClick: PropTypes.func,
  onSurpriseBackdropClick: PropTypes.func,
  raised: PropTypes.bool.isRequired,
  receivedBlocks: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  url: PropTypes.string,
};

export default injectIntl(StageSelector);
