import {CustomScratchBlocks} from "lib/scratch-blocks";
import defineDynamicBlock from "lib/define-dynamic-block";

const defineBlocks_ = (blockInfoArray, categoryInfo) => {
    if (blockInfoArray && blockInfoArray.length > 0) {
        const staticBlocksJson: any[] = [];
        const dynamicBlocksInfo: any[] = [];
        blockInfoArray.forEach((blockInfo: any) => {
            if (blockInfo.info && blockInfo.info.isDynamic) {
                dynamicBlocksInfo.push(blockInfo);
            } else if (blockInfo.json) {
                staticBlocksJson.push(blockInfo.json);
            }
            // otherwise it's a non-block entry such as '---'
        });


        CustomScratchBlocks.defineBlocksWithJsonArray(staticBlocksJson);
        dynamicBlocksInfo.forEach((blockInfo: any) => {
            // This is creating the block factory / constructor -- NOT a specific instance of the block.
            // The factory should only know static info about the block: the category info and the opcode.
            // Anything else will be picked up from the XML attached to the block instance.
            const extendedOpcode = `${categoryInfo.id}_${blockInfo.info.opcode}`;
            const blockDefinition =
                defineDynamicBlock(CustomScratchBlocks, categoryInfo, blockInfo, extendedOpcode);
            CustomScratchBlocks.Blocks[extendedOpcode] = blockDefinition;
        });
    }
};

// 向scratch-blocks中注入block/menu的声明
export const defineBlocks = (categoryInfo: any) => {
    // scratch-blocks implements a menu or custom field as a special kind of block ("shadow" block)
    // these actually define blocks and MUST run regardless of the UI state
    defineBlocks_(
        Object.getOwnPropertyNames(categoryInfo.customFieldTypes)
            .map(fieldTypeName => categoryInfo.customFieldTypes[fieldTypeName].scratchBlocksDefinition), categoryInfo);
    defineBlocks_(categoryInfo.menus, categoryInfo);
    defineBlocks_(categoryInfo.blocks, categoryInfo);
}

// 移除block/menu的声明(移除旧的声明, 才能添加新的声明)
export const undefineBlocks = (extensionId: string) => {
    for (let opcode in CustomScratchBlocks.Blocks) {
        if (opcode.indexOf(`${extensionId}_`) === 0){
            delete CustomScratchBlocks.Blocks[opcode];
        }
    }
}
