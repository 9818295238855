import { DFCheckboxGroup } from "component/df-checkbox/DFCheckbox";
import { DFDrawer } from "component/df-drawer/DFDrawer";
import DFPagination from "component/df-pagination/DFPagination";
import { DFTag, DFTagGroup } from "component/df-tag/DFTag";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectAssetLibStatus } from "redux/asset-lib/assetLibStatusSlice";
import { ExtensionLibrariesItem } from "../extension-libraries/extension-libraries-item/ExtensionLibrariesItem";
import styles from "./ExtensionLibraries.module.scss";
import BackIcon from "./icon--back.svg";
import { useClose } from "./util/useClose";
import { useStatusFilterTags } from "./util/useStatusFilterTags";
import { useLibrariesData } from "./util/useLibrariesData";
import { useSortExtension } from "./util/useSortExtension";
import { useFilterExtension } from "./util/useFilterIExtension";
import { COUNT_PER_PAGE, usePagination } from "./util/usePagination";
import { IS_SCRATCH_MODE } from "config/config";
import { useSearchExtension } from "./util/useSearchExtension";




export const ExtensionLibraries = () => {
    const intl = useIntl();
    const searchBarRef = useRef<HTMLDivElement>(null);
    const { extensionLibVisible } = useSelector(selectAssetLibStatus);
    const [drawerTop, setDrawerTop] = useState(108)
    const [searchVisible, setSearchVisible] = useState(false);
    const [libType, setLibType] = useState<"extension" | "device" | "stage">(
        IS_SCRATCH_MODE ? "stage" : "device"
    );
    const onClose = useClose(extensionLibVisible);
    // list数据
    const librariesData = useLibrariesData(libType);
    const { statusFilterTags, selectedStatusTags, handleSelectedStatusTagChange, statusFiltedData } = useStatusFilterTags(libType, librariesData)
    const { filterItems, handleSelectTag, handleRemoveSelectedTag, handleClearSelectedTag, selectedTag: selectedTagItems, showMainFilter, filteredItems } = useFilterExtension(libType, statusFiltedData, searchVisible);
    const { searchedExtensions, searchText, handleSearchTextChange } = useSearchExtension(filteredItems,libType)
    const { currentPage, handlePageNumberChange, pagedItems } = usePagination(searchedExtensions, libType)
    const { sortMethod, handleSortMethodChange } = useSortExtension(pagedItems, libType)

    useEffect(() => {
        if (extensionLibVisible) {
            if (IS_SCRATCH_MODE) {
                setLibType("stage")
            } else {
                setLibType("device")
            }
        };
    }, [extensionLibVisible]);
    useEffect(() => {
        const handleChangeSearchTopValue = () => {
            if (searchVisible && libType) {
                const rect = searchBarRef.current?.getBoundingClientRect();
                if (rect) {
                    setDrawerTop(rect.top + rect.height);
                }
            }
        }
        window.addEventListener("resize", handleChangeSearchTopValue)
        handleChangeSearchTopValue();
        return () => {
            window.removeEventListener("resize", handleChangeSearchTopValue)
        }

    }, [searchVisible, libType])

    // 筛选时翻到第一页
    useEffect(() => {
        //搜索时翻到第一页
        if (searchText) {
            handlePageNumberChange(1)
        }
        if (selectedTagItems && Object.keys(selectedTagItems).every(key => selectedTagItems[key].values.length)) {
            handlePageNumberChange(1)
        }
        if (selectedStatusTags.length) {
            handlePageNumberChange(1)
        }
    }, [handlePageNumberChange, searchText, selectedStatusTags, selectedTagItems])


    const SortByDefault = () => {
        return (
            <div className={`${styles.sortByDefault} ${sortMethod === "default" ? styles.selected : ''}`}
                onClick={() => handleSortMethodChange('default')}
            >
                <span>默认排序</span>
            </div >
        )
    }

    const SortByHot = () => {
        return (
            <div className={`${styles.sortMethod} ${sortMethod === "hot" ? styles.selectedUp : ''} ${sortMethod === "cold" ? styles.selectedDown : ''}`}
                onClick={() => handleSortMethodChange('hot')}
            >
                <span>热度</span>
            </div>
        )
    }

    const SortByTime = () => {
        return (
            <div className={`${styles.sortMethod} ${sortMethod === "newest" ? styles.selectedUp : ''} ${sortMethod === "oldest" ? styles.selectedDown : ''}`}
                onClick={() => handleSortMethodChange('newest')}
            >
                <span>发布时间</span>
            </div>
        )
    }

    const SelectedTags = () => {
        return (
            <div className={styles.selectedTags}>
                <div className={styles.selectedTagLeft}>
                    {Object.keys(selectedTagItems).map((key) => {
                        const element = selectedTagItems[key];
                        return (element.values).map((item) => {
                            return <DFTag
                                label={element.options.find((option) => option.value === item)?.label as string}
                                wrapperClassName={styles.selectedTag}
                                type={"text"}
                                selected={true}
                                closeAble={true}
                                square={element.checkbox}
                                round={!element.checkbox}
                                key={item}
                                onClose={() => { handleRemoveSelectedTag(key, item) }}></DFTag>
                        })
                    })}
                </div>
                <div className={styles.selectedTagRight} onClick={handleClearSelectedTag}>
                    <ClearIcon />
                    清空
                </div>
            </div>
        )
    }

    const SearchBar = () => {
        return (
            <div className={styles.searchBar}>
                <div className={`${styles.searchButton} ${searchVisible ? styles.selected : ''}`}>
                    <button onClick={() => setSearchVisible(!searchVisible)}>高级筛选</button>
                </div>
                <div className={styles.searchContent}>
                    <div className={styles.searchBarLeft}>
                        <div className={styles.categoryFilter}>
                            {
                                !showMainFilter ? <SelectedTags /> : (
                                    filterItems[0] && <DFTagGroup
                                        value={(selectedTagItems[filterItems[0].key]?.values as any) || []}
                                        wrapperClassName={styles.filterContent}
                                        options={filterItems[0].items}
                                        multiple={filterItems[0].checkbox}
                                        type="primary"
                                        onChange={(val) => { handleSelectTag(filterItems[0].key, val) }} />)
                            }
                        </div>
                        <div className={styles.statusFilter}>
                            <DFCheckboxGroup wrapperStyle={{ display: 'inline-flex', columnGap: '18px' }} options={statusFilterTags} onChange={handleSelectedStatusTagChange} value={selectedStatusTags} />
                        </div>
                    </div>
                    <div className={styles.searchBarRight}>
                        <SortByDefault />
                        {/* <SortByTime />
                        <SortByHot /> */}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.extensionLibraries}>
            <div className={styles.header}>
                <div onClick={onClose} className={styles.closeButton}>
                    <img src={BackIcon} alt="" />
                    <span>返回</span>
                </div>
                <div className={styles.tabs}>
                    {
                        IS_SCRATCH_MODE && <div
                            className={libType === "stage" ? styles.tabSelected : styles.tab}
                            onClick={() => setLibType("stage")}
                        >
                            舞台扩展
                        </div>
                    }
                    <div
                        className={libType === "device" ? styles.tabSelected : styles.tab}
                        onClick={() => setLibType("device")}
                    >
                        主控扩展
                    </div>
                    <div
                        className={libType === "extension" ? styles.tabSelected : styles.tab}
                        onClick={() => setLibType("extension")}
                    >
                        模块扩展
                    </div>
                </div>
                <div className={styles.search}>
                    <div className={styles.searchIconContainer}>
                        <SearchIcon />
                    </div>
                    <div className={styles.searchInputContainer}>
                        <input className={styles.searchInput} value={searchText} placeholder="搜索" onChange={handleSearchTextChange} />
                    </div>
                </div>
            </div>
            <div ref={searchBarRef}>
                <SearchBar />
            </div>
            <div className={styles.content}>
                {pagedItems.map((item) => (
                    <ExtensionLibrariesItem
                        libType={libType}
                        key={item[0].id}
                        items={item}
                    />
                ))}
            </div>
            <div className={styles.footer}>
                <DFPagination pageSize={COUNT_PER_PAGE} currentPage={currentPage} totalCount={searchedExtensions.length} onPageChange={handlePageNumberChange} />
            </div>
            <DFDrawer maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} zIndex={1000000} open={searchVisible} onClose={() => setSearchVisible(false)} placement="top" rootStyle={{ top: `${drawerTop}px` }} >
                <div className={styles.searchDrawer}>
                    {
                        filterItems.length > 0 && (
                            filterItems.map((item) => (
                                <div className={styles.filterItem}>
                                    <div className={styles.filterCaption}>
                                        {item.name}
                                    </div>
                                    <div className={styles.filterContentWrapper}>
                                        <DFTagGroup
                                            value={(selectedTagItems[item.key]?.values as any) || []}
                                            wrapperClassName={styles.filterContent}
                                            options={item.items}
                                            multiple={item.checkbox}
                                            onChange={(val) => { return handleSelectTag(item.key, val) }} />
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>
            </DFDrawer>
        </div>
    );
};


const SearchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="none"
            version="1.1" width="13.333333969116211" height="13.333333969116211"
            viewBox="0 0 13.333333969116211 13.333333969116211" className="searchIcon">
            <g>
                <path d="M6,1.33333C3.42267,1.33333,1.33333,3.42267,1.33333,6C1.33333,8.57733,3.42267,10.6667,6,10.6667C7.25727,10.6667,8.39847,10.1695,9.2376,9.36093C9.25513,9.33813,9.27433,9.31613,9.29527,9.29527C9.31613,9.27433,9.33813,9.25513,9.36093,9.2376C10.1695,8.39847,10.6667,7.25727,10.6667,6C10.6667,3.42267,8.57733,1.33333,6,1.33333C6,1.33333,6,1.33333,6,1.33333ZM10.6879,9.74513C11.509,8.71867,12,7.41667,12,6C12,2.68629,9.31373,0,6,0C2.68629,0,0,2.68629,0,6C0,9.31373,2.68629,12,6,12C7.41667,12,8.71867,11.509,9.74513,10.6879C9.74513,10.6879,12.1953,13.1381,12.1953,13.1381C12.4556,13.3984,12.8777,13.3984,13.1381,13.1381C13.3984,12.8777,13.3984,12.4556,13.1381,12.1953C13.1381,12.1953,10.6879,9.74513,10.6879,9.74513C10.6879,9.74513,10.6879,9.74513,10.6879,9.74513Z" fillRule="evenodd" fill="#FFEDCA" fillOpacity="1" />
            </g>
        </svg>
    )
}

const ClearIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="none" version="1.1"
            width="12" height="12"
            viewBox="0 0 17 17">
            <g>
                <path d="M1.7,5.1L15.3,5.1L15.3,16.15C15.3,16.6194,14.9194,17,14.45,17L2.55,17C2.08056,17,1.7,16.6194,1.7,16.15L1.7,5.1ZM4.25,2.55L4.25,0.85C4.25,0.380558,4.63056,0,5.1,0L11.9,0C12.3694,0,12.75,0.380558,12.75,0.85L12.75,2.55L17,2.55L17,4.25L0,4.25L0,2.55L4.25,2.55ZM5.95,1.7L5.95,2.55L11.05,2.55L11.05,1.7L5.95,1.7ZM5.95,8.5L5.95,13.6L7.65,13.6L7.65,8.5L5.95,8.5ZM9.35,8.5L9.35,13.6L11.05,13.6L11.05,8.5L9.35,8.5Z" fill="#D0D0D0" fillOpacity="1" />
            </g>
        </svg>
    )
}


