import ScratchQuestion from './question'
import React, {useEffect, useState} from "react";
import {vm} from "../../lib/scratch-vm";
import styles from "./index.module.scss";
import {getStageDimensions} from "../../lib/screen-utils";
import {useSelector} from "react-redux";
import {selectMode} from "../../redux/mode/modeSlice";
import {selectStageSize} from "../../redux/stage-size/stageSizeSlice";

let _question: any = null;

export const Question = () => {
    const [question, setQuestion_] = useState<any>(_question);
    const {isPlayerOnly, isFullScreen} = useSelector(selectMode)
    const {stageSize} = useSelector(selectStageSize)

    const setQuestion = (q) => {
        setQuestion_(q);
        _question = q;
    }
    useEffect(() => {
        const questionListener = (question_) => {
            setQuestion(question_);
        }
        vm.runtime.addListener('QUESTION', questionListener);
        return () => {
            vm.runtime.removeListener('QUESTION', questionListener);
        };
    }, []);

    const onQuestionAnswered = (answer) => {
        setQuestion(null);
        setTimeout(()=>vm.runtime.emit('ANSWER', answer))
    }
    const stageDimensions = getStageDimensions(stageSize, isFullScreen);
    return (

        <div
            className={styles.questionWrapper}
            style={{width: stageDimensions.width}}
        >
            {question !== null&&<ScratchQuestion
                question={question}
                onQuestionAnswered={onQuestionAnswered}
            />}
        </div>
    )
}
