// eslint-disable-next-line func-style,require-jsdoc
export function evalJsCode (content) {
    try {
        // eslint-disable-next-line no-eval
        const extension = eval(content);
        return extension;
    } catch (e) {
        console.error('eval Error', e);
        return null;
    }
}
